import StarRating from "components/rating/star-rating";
import { Context } from "context/context";
import { useContext } from "react";
import { btnCorner, sanitizeValueHTML } from "utils/constant";
import HeaderExample from "./component/header";

const showDummy = ["template", "content"];

export default function CouponsTab({ popup }) {
  const { view, selectedTab } = useContext(Context);
  const mobile = view === "mobile";
  const show = showDummy.includes(selectedTab);
  const data = popup.template_content && JSON.parse(popup.template_content);

  return (
    <div
      className={!mobile ? "p-[25px]" : ""}
      style={{
        marginBottom: mobile ? "50px" : "0",
        height: mobile ? "auto" : "635px",
      }}
    >
      <HeaderExample data={data} popup={popup} show={show} />
      {/* icons */}
      <div className="pt-6 text-[8px]">
        {/*  */}
        {(data?.coupons || []).map((obj, index) => {
          return (
            <div
              key={index}
              className={`${!obj.enabled && "hidden"} ${
                mobile
                  ? "flex-col gap-2"
                  : "gap-4 items-center justify-between flex-row"
              }
              border-2 border-secondary border-dashed flex mb-4 rounded-2xl w-full p-4`}
              style={{
                borderColor: popup?.element_colour || "#000000",
                borderRadius:
                  popup?.button_corner_style === "rounded"
                    ? "16px"
                    : btnCorner(popup?.button_corner_style),
              }}
            >
              <div className="min-w-[25%]">
                {(obj.title || show) && (
                  <div
                    className="text-sm leading-4 text-secondary font-bold mb-2"
                    style={{
                      color: popup?.element_colour || "#000000",
                    }}
                  >
                    {obj.title || "50% Off"}
                  </div>
                )}
                {(obj.subtitle || show) && (
                  <div className="font-bold uppercase leading-2">
                    {obj.subtitle || "Your next purchase"}
                  </div>
                )}
              </div>
              {(obj.description || data.termsText || show) && (
                <div className="flex flex-col justify-between gap-2 min-w-[30%]">
                  {(obj.description || show) && (
                    <div
                      className="leading-2"
                      dangerouslySetInnerHTML={{
                        __html:
                          sanitizeValueHTML(obj.description) ||
                          "Coupon cannot be used in conjunction with any other offer.",
                      }}
                    />
                  )}
                  {(obj.termsText || show) &&
                    (obj.termsText ? (
                      <div
                        style={{
                          color: popup?.element_colour || "#000000",
                        }}
                      >
                        {obj.termsLink ? (
                          <span className="underline cursor-pointer">
                            {obj.termsText}
                          </span>
                        ) : (
                          obj.termsText
                        )}
                      </div>
                    ) : (
                      "Terms and Conditions Apply"
                    ))}
                </div>
              )}
              {(obj.code || obj.expiryText || show) && (
                <div className="min-w-[30%] flex flex-col justify-center items-center gap-2">
                  {(obj.code || show) && (
                    <div
                      className="w-full text-center text-xxs uppercase font-bold border-2 border-secondary border-dashed rounded-full p-2 overflow-hidden relative"
                      style={{
                        borderColor: popup?.element_colour || "#000000",
                        borderRadius: btnCorner(popup?.button_corner_style),
                      }}
                    >
                      {!show && (
                        <div
                          className="absolute top-0 bottom-0 right-4 left-0 flex items-center justify-center"
                          style={{
                            color: popup?.element_text_colour || "#FFFFFF",
                            backgroundColor: popup?.element_colour || "#000000",
                            borderRadius: btnCorner(popup?.button_corner_style),
                          }}
                        >
                          <div>Reveal Code</div>
                        </div>
                      )}
                      {obj.code || "50OFF"}
                    </div>
                  )}
                  {(obj.expiryText || show) && (
                    <div className="text-center">
                      {obj.expiryText || "Expires 01/01/20XX"}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="pt-5">
        {show || data.testimonialRating ? (
          <StarRating value={data.testimonialRating} />
        ) : null}
        <div
          className={`${
            show || data.testimonialContent ? "pt-3" : ""
          } text-sm ${!data?.testimonialContent && "text-tabBorder"}`}
        >
          {data?.testimonialContent ||
            (show &&
              "“Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.”")}
        </div>
        <div className="pt-1">
          <p
            className={`text-13 font-bold ${
              !data?.testimonialAuthor && "text-tabBorder"
            }`}
          >
            {data?.testimonialAuthor || (show && "Jane Doe")}
          </p>
        </div>
      </div>
    </div>
  );
}
