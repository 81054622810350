const NAME_MAP = {
  "proof-points": "proofpoints",
  testimonials: "testimonial",
  videos: "videos",
  promotions: "promotion",
  "statistics-data": "statistics",
  logos: "logo",
  coupons: "coupons",
  "standard-details": "standard",
  "enquiry-form": "enquiry",
  "lead-magnet": "lead",
  "map-assistance": "map",
  "multiple-addresses": "address",
  "calendy-event-scheduler": "calendy",
  "hubspot-form": "hubspot",
};

export const getPopoutImage = (template, contact) => {
  const templateName = NAME_MAP[template];
  const contactName = NAME_MAP[contact];
  return templateName && contactName
    ? `/popout-wireframes/${templateName}-${contactName}.png`
    : false;
};
