import RecaptchaEmptySVG from "assets/svg/recaptcha-empty-svg";
import _ from "lodash";
import numeral from "numeral";
import { Website } from "../../../../constants/images";

const TEMPLATE_CONTACT = {
  template: {
    "proof-points": "Proof Points",
    testimonials: "Testimonials",
    videos: "Videos",
    promotions: "Promotions",
    "statistics-data": "Statistics/Data",
    logos: "Logos",
    coupons: "Coupons",
  },
  contacts: {
    "standard-details": "Standard Details",
    "enquiry-form": "Enquiry Form",
    "lead-magnet": "Lead Magnet",
    "map-assistance": "Map Assistance",
    "multiple-addresses": "Multiple Addresses",
    "calendy-event-scheduler": "Calendly Event Scheduler",
    "hubspot-form": "HubSpot Form",
  },
};

export default function PerformingPopouts({ dataSource = [] }) {
  return (
    <table className="w-full">
      <tbody className="divide-y divide-thBorder">
        {_.isEmpty(dataSource) ? (
          <tr>
            <td align="center">
              <div className="w-full h-full mt-10">
                <RecaptchaEmptySVG className="w-32 h-32" />
                <p className="pt-3 text-sm opacity-70">
                  No performing popouts yet
                </p>
              </div>
            </td>
          </tr>
        ) : (
          <>
            {dataSource.map((obj, index) => {
              let stats = obj.report;

              return (
                <tr key={index}>
                  <td className="pr-3">
                    <div className="table-number">{index + 1}</div>
                  </td>
                  <td className="pl-3 py-4 flex space-x-5">
                    <div className="table-website relative">
                      <img
                        className="h-full w-full"
                        src={Website}
                        alt="performing-popouts"
                      />
                    </div>
                    <div className="mt-2 grid">
                      <div className="w-full">
                        <p className="text-lg tracking-normalwide leading-6.5">
                          {obj.popup?.title}
                        </p>
                        <p className="text-xs tracking-normalwide leading-4.3 text-secondary">
                          {`${
                            TEMPLATE_CONTACT.template[
                              obj.popup?.template_type
                            ] || "Not Available"
                          } & ${
                            TEMPLATE_CONTACT.contacts[
                              obj?.popup?.contact_method_type
                            ] || "Not Available"
                          }`}
                        </p>
                      </div>
                      <div className="flex space-x-5 items-end divide-x-2 divide-sortBg">
                        <div>
                          <p className="text-lg leading-6.5">
                            {numeral(stats?.opens).format("0,0")}
                          </p>
                          <p className="text-xs leading-3.5 opacity-50">
                            Visitors
                          </p>
                        </div>
                        <div className="pl-5">
                          <p className="text-lg leading-6.5">
                            {numeral(stats?.clicks).format("0,0")}
                          </p>
                          <p className="text-xs leading-3.5 opacity-50">
                            Opens
                          </p>
                        </div>
                        <div className="pl-5">
                          <p className="text-lg leading-6.5">
                            {numeral(stats?.interactions).format("0,0")}
                          </p>
                          <p className="text-xs leading-3.5 opacity-50">
                            Interactions
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
}
