import LoadingIndicator from "assets/svg/loading-indicator";
import Button from "components/button/button";
import FileUpload from "components/file-upload/file-upload";
import Input from "components/input/input";
import Toggle from "components/toggle/toggle";
import WYSIWYGEditorDraft, {
  convertToEditorState,
} from "components/wysiwyg/wysiwyg-editor";
import { Context } from "context/context";
import update from "immutability-helper";
import _ from "lodash";
import { useContext, useMemo, useState } from "react";
import toast from "react-hot-toast";
import {
  MAX_TITLE_LENGTH,
  PROMOTIONSUGGESTIONS,
  sanitizeValueHTML,
  TEMPLATEOBJ,
} from "utils/constant";
import SuggestionButton from "./component/suggestions-btn";

const PLACHOLDER = [
  "30 days free trial",
  "No lock in contracts",
  "Easy to install",
  "No lock in contracts",
];

export default function Promotions({
  handleChangeStep = () => console.log("We are missing a function here"),
  handleOnSave = () => {},
  loading = false,
  validationErrors = {},
  setState,
}) {
  const { setSelectedTab, popup, setPopup } = useContext(Context); // context
  const { http } = global.services;
  const initials = useMemo(() => {
    if (_.isEmpty(popup?.template_content)) {
      return _.find(TEMPLATEOBJ, { type: "promotions" });
    } else {
      return JSON.parse(popup?.template_content);
    }
  }, [popup.template_content]);
  // start wysiwyg
  const titleInitials = useMemo(() => {
    let data = {};
    if (_.isEmpty(popup?.template_content)) {
      data = _.find(TEMPLATEOBJ, { type: "promotions" });
    } else {
      data = JSON.parse(popup?.template_content);
    }
    return convertToEditorState(data.title || "");
  }, [popup.template_content]);
  const [editorState, setEditorState] = useState(titleInitials);
  // end wysiwyg

  const [object, setObject] = useState(initials);
  const [suggestion, setSuggestion] = useState(0);
  const [image, setImage] = useState({
    uploading: false,
    id: null,
    image_key: null,
  });

  const handleChangeObject = (value, element) => {
    let payload = _.clone(object);
    let data = update(payload, {
      [element]: { $set: value },
    });

    setObject(data);
    handleOnSave(data);
  };

  const handleChange = (value, element) => {
    let payload = _.clone(object);
    let finalValue = value;
    let data = payload;
    if (element === "title") {
      if (_.isEmpty(_.trim(value.replace(/(<([^>]+)>)/gi, "")))) {
        finalValue = "";
        data = update(payload, {
          [element]: { $set: finalValue },
          title_decoration: { $set: "normal" },
        });
      } else {
        let sanitizeValue = sanitizeValueHTML(finalValue);
        data = update(payload, {
          [element]: { $set: sanitizeValue },
          title_decoration: { $set: "normal" },
        });
      }
    } else {
      data = update(payload, {
        [element]: { $set: finalValue },
      });
    }
    setObject(data);
    //set to popout
    if (element === "title") {
      setPopup((prev) => ({
        ...prev,
        template_content: JSON.stringify(data),
      }));
    }
  };

  const handleChangeValue = (value, index, element) => {
    let payload = _.clone(object);
    let data = update(payload, {
      points: {
        [index]: {
          [element]: { $set: value },
        },
      },
    });
    setObject(data);
    if (element === "visible") {
      handleOnSave(data);
    }
  };

  const onUploadImage = async (e) => {
    setImage((prevState) => ({ ...prevState, uploading: true }));
    let payload = _.clone(object);
    const formData = new FormData();
    const file = e.target.files[0];

    try {
      if (file.size > 300000) {
        setImage((prevState) => ({ ...prevState, uploading: false }));
        return toast.error(
          "Oops! The file is too large. Max file size: 300KB."
        );
      }

      formData.append("file", file);
      formData.append("type", "templates");
      const uploadResult = await http.post("medias", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const result = uploadResult.data.data;
      if (result) {
        /* Remove old image when attempting to re-upload new image */
        if (image.id && image.image_key) {
          await http.delete(`medias/${image.id}`, {
            key: image.image_key,
          });
        }

        let put = update(payload, {
          //update object
          thumbnail: {
            url: { $set: result.presigned_url },
            media_id: { $set: result.id },
            width: { $set: result.width },
            height: { $set: result.height },
          },
        });
        setObject(put);
        setImage((prev) => ({
          ...prev,
          id: result.id,
          image_key: result.key,
        }));
        //handle on save
        handleOnSave(put);
      }
    } catch (error) {
      toast.error(
        "An unknown error occured. Please refresh the page and try again."
      );
    } finally {
      setImage((prevState) => ({ ...prevState, uploading: false }));
      e.target.value = "";
    }
  };

  const validateBeforeNext = () => {
    let payload = _.clone(validationErrors);
    if (_.isEmpty(object?.thumbnail?.url)) {
      payload.thumbnail = "Thumbnail is required";
    }

    setState((prev) => ({
      ...prev,
      validationErrors: {
        ...prev.validationErrors,
        ...payload,
      },
    }));
    if (_.isEmpty(payload)) {
      handleChangeStep();
    }
  };

  const handleOnBlur = (e) => {
    handleOnSave(object);
  };

  const handleRemoveImage = async () => {
    setImage((prevState) => ({ ...prevState, uploading: true }));
    let payload = _.clone(object);

    if (payload.thumbnail) {
      const key = payload.thumbnail?.url.split(".com")[1]; // split the url to get the image key
      image.id = payload.thumbnail?.media_id || null;
      image.image_key = key || null;
    }

    try {
      if (image.id && image.image_key) {
        const removeImageResult = await http.delete(`medias/${image.id}`, {
          key: image.image_key,
        });

        if (removeImageResult.data?.success) {
          toast.success("Image removed successfully");
          let put = update(payload, {
            //update object
            thumbnail: {
              url: { $set: null },
              media_id: { $set: null },
              image_width: { $set: null },
              mage_height: { $set: null },
            },
          });
          setObject(put);
          setImage((prev) => ({
            ...prev,
            id: null,
            image_key: null,
          }));
          //handle on save
          handleOnSave(put);
        }
      }
    } catch (error) {
      setImage((prevState) => ({ ...prevState, uploading: false }));
      if (error.status === 422)
        toast.error("It appears that something went wrong");
    } finally {
      setImage((prevState) => ({ ...prevState, uploading: false }));
    }
  };

  //suggesstions
  const handleSuggestion = (type) => {
    const title = PROMOTIONSUGGESTIONS;
    const size = _.size(PROMOTIONSUGGESTIONS) - 1;
    let value = null;
    if (type === "next") {
      if (suggestion > size) {
        setSuggestion(1);
        value = title[0];
      } else {
        value = title[suggestion];
        setSuggestion((prev) => prev + 1);
      }
    } else {
      let counter = suggestion - 2;
      if (suggestion === 0 || suggestion === 1) {
        value = title[size];
        setSuggestion(size);
      } else {
        if (counter > 0) {
          value = title[counter];
          setSuggestion((prev) => prev - 1);
        } else if (counter === 0) {
          value = title[counter];
          setSuggestion(size + 2);
        }
      }
    }
    if (value) {
      let payload = _.clone(object);
      let data = update(payload, {
        title: { $set: value },
      });
      //handle set
      setEditorState(convertToEditorState(value));
      setObject(data);
      handleOnSave(data);
    }
  };

  return (
    <>
      {/* header */}
      <div className="w-full">
        <p className="font-medium text-2xl">Add Your Content</p>
      </div>
      {/* end header */}
      <div className="w-full pt-5">
        {/* left card start */}
        <div
          className="bg-white rounded-xl relative campaign-left-max"
          style={{ height: "fit-content" }}
        >
          <div className="p-5">
            {/* first */}
            <div>
              <p className="text-xl pl-1 pb-2">Design your promotions</p>
            </div>
            {/* middle */}
            <div className="w-full mb-2">
              <p className="pl-1 pt-2 pb-2">Type your heading here:</p>
              <WYSIWYGEditorDraft
                placeholder={`e.g. Excepteur sint occaecat cupidatat non proident, sunt in cul...`}
                onChange={(value) => {
                  handleChange(value, "title");
                }}
                onBlur={handleOnBlur}
                errorMessage={validationErrors?.title}
                showCounter={true}
                maxLength={MAX_TITLE_LENGTH}
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </div>
            {/* title_decoration */}
            <div className="w-full flex items-center 5md:block 3lg:flex 3lg:items-center justify-start">
              <SuggestionButton onClick={(e) => handleSuggestion(e)} />
            </div>
            {validationErrors?.thumbnail && (
              <p className="text-red text-xs mt-3 mb-3">
                * {validationErrors?.thumbnail}
              </p>
            )}
            <p className="pl-1 pt-6 pb-2">
              Upload your promotional image here:
            </p>
            <FileUpload
              title="Full Image For Your Promotion"
              leftContentClass="w-full image-view-promotion"
              otherLabelClass="mt-6"
              wrapperClass="mt-3"
              accept="image/png, image/jpeg, image/gif" // default: null, files to accept
              width={"100%"} // default: 100%, width for right section label
              image_url={object?.thumbnail?.url} // default: null, image fetched when uploaded to server
              uploading={image.uploading} // default: false, upload loader
              maxFileSize="300KB"
              recommendedImageSize="120 x 160px"
              allowedFiles="JPG, PNG, GIF"
              name="template-promotions"
              id="template-promotions"
              onChange={onUploadImage}
              removable={true}
              onRemoveImage={handleRemoveImage}
            />
            <div className="mt-5">
              <p className="pl-1 pt-2 pb-2">Complete your content:</p>
              <Toggle
                background="bgdefault"
                name="show_ticks"
                checked={!object?.show_ticks}
                onChange={(e) =>
                  handleChangeObject(!e?.target?.checked, "show_ticks")
                }
                icon="promotions"
                className="w-60 h-10"
                custom="promotions"
                labelLeft="Tick Points"
                labelRight="Text Only"
              />
            </div>
            {/* Uploads and others */}
            <div className="mt-3">
              {/* others textfield */}
              {(object?.points || []).map((value, index) => (
                <Input
                  key={index}
                  inputContainerClass="input-container-2"
                  paddingTop="1.5rem"
                  labelClass="input-label-xs"
                  placeholder={`e.g. ${PLACHOLDER[index]}`}
                  label={`Tick Point ${index + 1}`}
                  name="text"
                  type="text"
                  value={value?.text}
                  visible={value?.visible}
                  showCounter={true}
                  maxLength={40}
                  masked={true}
                  onChange={(e) =>
                    handleChangeValue(e?.target?.value, index, "text")
                  }
                  onChangeVisible={() => {
                    handleChangeValue(!value.visible, index, "visible");
                  }}
                  onBlur={handleOnBlur}
                  errorMessage={validationErrors?.text}
                />
              ))}
            </div>
          </div>
          {/* footer */}
          <div className="border-t border-tabBorder">
            <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
              <Button
                buttonName="PREVIOUS"
                buttonClass="bg-transparent rounded-full py-3"
                buttonType="transparent"
                buttonTextClass="text-sm font-bold"
                onClick={() => setSelectedTab("template")}
              />
              <Button
                buttonName="NEXT"
                buttonClass="relative bg-primary rounded-full py-3 px-12"
                buttonType="primary"
                onClick={validateBeforeNext}
                disabled={loading}
                icon={
                  <div className="absolute right-2 bottom-0 top-0 flex items-center">
                    {loading && (
                      <LoadingIndicator className="text-white w-4 h-4" />
                    )}
                  </div>
                }
              />
            </div>
          </div>
        </div>
        {/* end card start */}
      </div>
    </>
  );
}
