import Button from "components/button/button";
import WYSIWYGEditorDraft, {
  convertToEditorState,
} from "components/wysiwyg/wysiwyg-editor";
import { Context } from "context/context";
import update from "immutability-helper";
import _ from "lodash";
import { useContext, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import {
  CONTACTOBJ,
  MAX_TITLE_LENGTH,
  TITLESUGGESTIONS,
  sanitizeValueHTML,
} from "utils/constant";
import { DisplayErrorMessage } from "utils/utils";
import validator from "validator";
import SuggestionButton from "../add-content/component/suggestions-btn";
import MultipleAddressesDetailsTab from "./component/multiple-addresses-details-tab";

export default function MultipleAddressesDetails({
  handleChangeStep,
  state,
  setState,
  handleOnSave,
}) {
  const {
    setSelectedTab,
    popup,
    disabledStylePopout,
    setDisabledStylePopout,
    setPopup,
  } = useContext(Context);
  const initials = useMemo(() => {
    if (_.isEmpty(popup?.contact_method_content)) {
      return _.find(CONTACTOBJ, { type: "multiple-addresses" });
    } else {
      return JSON.parse(popup?.contact_method_content);
    }
  }, [popup.contact_method_content]);
  // start wysiwyg
  const titleInitials = useMemo(() => {
    let data = {};
    if (_.isEmpty(popup?.contact_method_content)) {
      data = _.find(CONTACTOBJ, { type: "multiple-addresses" });
    } else {
      data = JSON.parse(popup?.contact_method_content);
    }
    return convertToEditorState(data.title || "");
  }, [popup.contact_method_content]);
  const [editorState, setEditorState] = useState(titleInitials);
  // end wysiwyg
  const [content, setContent] = useState(initials);
  const topRef = useRef(null);
  const [suggestion, setSuggestion] = useState(0);
  const [valError, setValError] = useState("");

  // ========================= validation start ==================================
  const validateEmail = (email, index) => {
    if (validator.isEmail(email)) {
      return "";
    } else {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          contacts: {
            ...prev.validationErrors?.contacts,
            [index]: {
              ...prev.validationErrors?.contacts?.[index],
              email_address: {
                value: ["The Email Address must be a valid email address."],
              },
            },
          },
        },
      }));
      return "The Email Address must be a valid email address.";
    }
  };

  const validateContactNumber = (number, index) => {
    if (_.isEmpty(_.trim(number))) {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          contacts: {
            ...prev.validationErrors?.contacts,
            [index]: {
              ...prev.validationErrors?.contacts?.[index],
              contact_number: {
                value: ["The Contact Number format is invalid."],
              },
            },
          },
        },
      }));
      return "The Contact Number format is invalid.";
    } else {
      if (number.match(/^[0-9 ()+]+$/)) {
        if (_.size(number) >= 3) {
          return "";
        } else {
          setState((prev) => ({
            ...prev,
            validationErrors: {
              ...prev.validationErrors,
              contacts: {
                ...prev.validationErrors?.contacts,
                [index]: {
                  ...prev.validationErrors?.contacts?.[index],
                  contact_number: {
                    value: [
                      "The Contact Number must be at least 3 characters.",
                    ],
                  },
                },
              },
            },
          }));
          return "The Contact Number must be at least 3 characters.";
        }
      } else {
        setState((prev) => ({
          ...prev,
          validationErrors: {
            ...prev.validationErrors,
            contacts: {
              ...prev.validationErrors?.contacts,
              [index]: {
                ...prev.validationErrors?.contacts?.[index],
                contact_number: {
                  value: ["The Contact Number format is invalid."],
                },
              },
            },
          },
        }));
        return "The Contact Number format is invalid.";
      }
    }
  };

  const validateAddress = (address, index) => {
    if (_.size(address) >= 3) {
      return "";
    } else {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          contacts: {
            ...prev.validationErrors?.contacts,
            [index]: {
              ...prev.validationErrors?.contacts?.[index],
              address: {
                value: ["The Address must be at least 3 characters."],
              },
            },
          },
        },
      }));
      return "The Address must be at least 3 characters.";
    }
  };

  const validateAddressPin = (pin, index) => {
    if (!_.isEmpty(pin)) {
      return "";
    } else {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          contacts: {
            ...prev.validationErrors?.contacts,
            [index]: {
              ...prev.validationErrors?.contacts?.[index],
              pin: {
                value: ["The Address pin is required."],
              },
            },
          },
        },
      }));
      return "The Address pin is required.";
    }
  };

  const checkPoint = () => {
    const contacts = _.clone(content.contacts);
    const fileds = [
      "address",
      "contact_number",
      "contact_title",
      "email_address",
    ];

    const obj = _.filter(contacts, function (o) {
      let payload = {
        address: false,
        contact_number: false,
        contact_title: false,
        email_address: false,
      };
      _.forEach(fileds, (e) => {
        if (!_.isEmpty(o[e].value) && o[e].visible) {
          payload[e] = true;
        }
      });
      if (
        payload.contact_title ||
        payload.email_address ||
        payload.contact_number ||
        payload.address
      ) {
        return true;
      } else {
        return false;
      }
    });

    return _.size(obj);
  };

  const validateBeforeSave = (content) => {
    let fields = ["contact_number", "email_address", "address"];
    let email_address = false;
    let contact_number = false;
    let address = false;
    let pin = false;
    let err = [];
    setDisabledStylePopout([]);
    setValError("");
    let contacts = (content.contacts || []).map((obj, index) => {
      _.forEach(obj, async (innerObj, key) => {
        if (fields.includes(key)) {
          if (obj[key].value) {
            if (key === "email_address") {
              let resultValidate = validateEmail(innerObj.value, index);
              if (_.isEmpty(resultValidate)) {
                email_address = true;
              } else {
                err.push(resultValidate);
              }
            }
            if (key === "contact_number") {
              let resultValidate = validateContactNumber(innerObj.value, index);
              if (_.isEmpty(resultValidate)) {
                contact_number = true;
              } else {
                err.push(resultValidate);
              }
            }
            if (key === "address") {
              let resultValidate = validateAddress(innerObj.value, index);
              if (_.isEmpty(resultValidate)) {
                address = true;
              } else {
                err.push(resultValidate);
              }
              let resultValidatePin = validateAddressPin(innerObj.pin, index);
              if (_.isEmpty(resultValidatePin)) {
                pin = true;
              } else {
                err.push(resultValidatePin);
              }
            }
          } else {
            //empty
            if (key === "email_address") {
              email_address = true;
            }
            if (key === "contact_number") {
              contact_number = true;
            }
            if (key === "address") {
              address = true;
              pin = true;
            }
          }
        }
      });
      if (email_address && contact_number && address && pin) {
        return true;
      } else {
        return false;
      }
    });

    if (contacts.every((element) => element)) {
      handleOnSave(content);
    } else {
      setDisabledStylePopout(err);
    }
  };
  // ========================= validation end ==================================
  //suggesstions
  const handleSuggestion = (type) => {
    const title = TITLESUGGESTIONS;
    const size = _.size(TITLESUGGESTIONS) - 1;
    let value = null;
    if (type === "next") {
      if (suggestion > size) {
        setSuggestion(1);
        value = title[0];
      } else {
        value = title[suggestion];
        setSuggestion((prev) => prev + 1);
      }
    } else {
      let counter = suggestion - 2;
      if (suggestion === 0 || suggestion === 1) {
        value = title[size];
        setSuggestion(size);
      } else {
        if (counter > 0) {
          value = title[counter];
          setSuggestion((prev) => prev - 1);
        } else if (counter === 0) {
          value = title[counter];
          setSuggestion(size + 2);
        }
      }
    }
    if (value) {
      let payload = _.clone(content);
      let data = update(payload, {
        title: { $set: value },
      });
      //handle set
      setEditorState(convertToEditorState(value));
      setContent(data);
      validateBeforeSave(data);
    }
  };

  //additional
  const handleChangeValue = (value, element) => {
    let payload = _.clone(content);
    let finalValue = value;
    let data = payload;
    if (element === "title") {
      if (_.isEmpty(_.trim(value.replace(/(<([^>]+)>)/gi, "")))) {
        finalValue = "";
        data = update(payload, {
          [element]: { $set: finalValue },
          title_decoration: { $set: "normal" },
        });
      } else {
        data = update(payload, {
          [element]: { $set: sanitizeValueHTML(finalValue) },
          title_decoration: { $set: "normal" },
        });
      }
    } else {
      data = update(payload, {
        [element]: { $set: finalValue },
      });
    }
    if (state.validationErrors && state.validationErrors[element]) {
      setState((prev) => ({
        ...prev,
        validationErrors: { ...state.validationErrors, [element]: null },
      }));
    }
    setContent(data);
    if (element === "title") {
      setPopup((prev) => ({
        ...prev,
        contact_method_content: JSON.stringify(data),
      }));
    }
  };

  const validateBeforeNext = () => {
    if (!_.isEmpty(disabledStylePopout)) {
      return toast.error(<DisplayErrorMessage errors={disabledStylePopout} />);
    } else {
      let val = checkPoint();
      if (val) {
        if (_.isEmpty(content?.title)) {
          topRef.current?.scrollIntoView({ behavior: "smooth" });
          toast.error("Contact title is required.");
        }
        handleChangeStep(content);
      } else {
        setValError("At least 1 Contact is required.");
        topRef.current?.scrollIntoView({ behavior: "smooth" });
        toast.error("At least 1 Contact is required.");
      }
    }
  };

  return (
    <>
      {/* header */}
      <div className="w-full" ref={topRef}>
        <p className="font-medium text-2xl">Complete Your Contact Details</p>
      </div>
      {/* end header */}
      <div className="w-full pt-5">
        {/* left card start */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validateBeforeNext();
          }}
          className="w-full bg-white rounded-xl relative campaign-left-max mr-32"
          style={{ height: "fit-content" }}
        >
          <div className="p-5">
            <p className="text-xl pl-1 pb-2">Enter up to 4 addresses</p>
            {/* middle */}
            <div>
              <p className="pl-1 pt-2 pb-2">Type your heading here:</p>
              {/* title */}
              <div className="mb-3">
                <WYSIWYGEditorDraft
                  placeholder={`e.g. Excepteur sint occaecat cupidatat non proident, sunt in cul...`}
                  onChange={(value) => {
                    handleChangeValue(value, "title");
                  }}
                  onBlur={() => validateBeforeSave(content)}
                  errorMessage={state.validationErrors?.title}
                  showCounter={true}
                  maxLength={MAX_TITLE_LENGTH}
                  editorState={editorState}
                  setEditorState={setEditorState}
                />

                <div className="w-full mt-2 flex items-center 5md:block 3lg:flex 3lg:items-center justify-between">
                  <SuggestionButton onClick={(e) => handleSuggestion(e)} />
                </div>
              </div>
              {/* tabs */}
              {!_.isEmpty(valError) && (
                <p className="text-red text-xs mb-3">* {valError}</p>
              )}
              <MultipleAddressesDetailsTab
                state={state}
                content={content}
                setContent={setContent}
                onHandleBlur={() => validateBeforeSave(content)}
              />
            </div>
          </div>
          {/* footer */}
          <div className="border-t border-tabBorder">
            <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
              <Button
                buttonName="PREVIOUS"
                buttonClass="bg-transparent rounded-full py-3"
                buttonType="transparent"
                buttonTextClass="text-sm font-bold"
                onClick={() => setSelectedTab("contactM")}
              />
              <Button
                buttonName="NEXT"
                buttonClass="relative bg-primary rounded-full py-3 px-12"
                buttonType="primary"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
