import StarIconPreview from "assets/svg/star-icon-preview";
import styled from "styled-components";
import { device } from "../../../screens";

const TestimonialsCardContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 2rem;
`;
const TestimonialsCardQuote = styled.div`
  margin: 0;
  letter-spacing: -0.03em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  font-size: 16px;
  line-height: 24px;

  @media ${device.xs} {
    font-size: 18px;
    line-height: 27px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
const TestimonialsCardAuthor = styled.div`
  margin-top: 16px;
  font-weight: 700;

  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  font-size: 14px;
  line-height: 17px;

  @media ${device.xs} {
    font-size: 16px;
    line-height: 19px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 18px;
    line-height: 22px;
    margin-top: 20px;
  }
`;

const StarContainer = styled.div`
  display: inline-flex;
  margin-bottom: 12px;
`;

export default function CouponTestimonialCard({
  width,
  rating,
  content,
  author,
}) {
  return (
    (rating || content || author) && (
      <TestimonialsCardContainer>
        {rating ? (
          <StarContainer>
            {Array(5)
              .fill(0)
              .map((_, ratingIndex) => {
                const count = ratingIndex + 1;
                return (
                  <StarIconPreview
                    active={rating >= count}
                    key={`testimonial-rating-${ratingIndex}`}
                    responsive={width}
                  />
                );
              })}
          </StarContainer>
        ) : null}
        <TestimonialsCardQuote responsive={width}>
          {content}
        </TestimonialsCardQuote>
        <TestimonialsCardAuthor responsive={width}>
          {author}
        </TestimonialsCardAuthor>
      </TestimonialsCardContainer>
    )
  );
}
