import DashboardClickFileIconSVG from "assets/svg/dashboard-click-file-icon";
import DashboardEyeIconSVG from "assets/svg/dashboard-eye-icon";
import DashboardInteractionIconSVG from "assets/svg/dashboard-interaction-icon";
import Card from "components/card/card";
import LeadGenerated from "components/dashboard/lead-generated";
import StatisticsDashboard from "components/dashboard/statistics-dashboard/statistics-dashboard";
import { FilterSelector } from "components/global/filterSelector";
import Select from "components/select/select";
import { Context } from "context/context";
import _ from "lodash";
import moment from "moment";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTabs } from "react-headless-tabs";
import { useParams } from "react-router-dom";
import { getDays, getMonths, getWeeks } from "utils/constant";
import PerformingPopouts from "./performing-popouts";
import TopEvents from "./top-events";

const filters = [
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
];

export default function CampaignStatistics() {
  const { http } = global.services;
  const { id } = useParams();
  const { campaign } = useContext(Context);
  const [filter, setFilter] = useTabs(["weekly", "monthly"]);
  const [filterValue, setFilterValue] = useState("");
  const [dateOptions, setDateOptions] = useState([]);
  const [filterDates, setFilterDates] = useState({
    start: moment().unix(),
    end: moment().unix(),
  });
  const [rawPopups, setRawPopups] = useState([]);

  const [metrics, setMetrics] = useState([
    {
      title: "Visitors",
      total: 0,
      previous: 0,
      icon: <DashboardEyeIconSVG />,
    },
    {
      title: "Opens",
      total: 0,
      previous: 0,
      icon: <DashboardClickFileIconSVG />,
    },
    {
      title: "Interactions",
      total: 0,
      previous: 0,
      icon: <DashboardInteractionIconSVG />,
    },
  ]);
  const [rawInteractions, setRawInteractions] = useState();

  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState({
    opens: [],
    interactions: [],
    impressions: [],
  });

  const fetchCampaignMetrics = async ({ id: campaign_id }) => {
    // we can set filter of date depends on the filter on the frontend
    let end = filterDates.end;
    let start = filterDates.start;

    const { data: response } = await http.get(
      `campaigns/${campaign_id}/reports/overview`,
      { end, start }
    );

    setMetrics((prev) =>
      prev.map((metric) => {
        if (metric.title === "Opens") {
          return { ...metric, total: response?.data?.unique_clicks || 0 };
        }

        if (metric.title === "Visitors") {
          //total Clicks
          return { ...metric, total: response?.data?.unique_opens || 0 };
        }

        if (metric.title === "Interactions") {
          return { ...metric, total: response?.data?.unique_interactions || 0 };
        }

        return metric;
      })
    );

    //bar graph
    const barLabels = getDays(moment.unix(start), moment.unix(end));
    if (!_.isEmpty(response.reports)) {
      getBarGraphData(barLabels, response.reports);
    } else {
      const values = barLabels.map(() => {
        return 0;
      });
      setLabels(barLabels);
      setDatasets({
        opens: values,
        interactions: values,
        impressions: values,
      });
    }

    //end bar graph
    if (!_.isEmpty(response.popups)) {
      setRawPopups(response.popups);
    } else {
      setRawPopups([]);
    }

    if (!_.isEmpty(response.interactions)) {
      setRawInteractions(response.interactions);
    } else {
      setRawInteractions(undefined);
    }
  };

  const getBarGraphData = (labels = [], reports = {}) => {
    setLabels(labels);
    let opens = [];
    let interactions = [];
    let impressions = [];
    labels.forEach((value) => {
      let payload = {
        opens: 0,
        interactions: 0,
        impressions: 0,
      };
      Object.entries(reports).forEach(([date, data]) => {
        let rec = moment(new Date(date)).format("MM/DD");
        if (value === rec) {
          payload.opens = data?.clicks;
          payload.interactions = data?.interactions;
          payload.impressions = data?.opens;
        }
      });
      opens.push(payload.opens);
      interactions.push(payload.interactions);
      impressions.push(payload.impressions);
    });
    setDatasets({
      opens: opens,
      interactions: interactions,
      impressions: impressions,
    });
  };

  useEffect(() => {
    let options = [];
    if (filter === "weekly") {
      options = getWeeks(campaign?.created_at);
      setDateOptions(options);
      setFilterValue(options[0].value);
      setFilterDates({
        start: options[0].dates.start,
        end: options[0].dates.end,
      });
    } else {
      options = getMonths(campaign?.created_at);
      setDateOptions(options);
      setFilterValue(options[0].value);
      setFilterDates({
        start: options[0].dates.start,
        end: options[0].dates.end,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, campaign]);

  useEffect(() => {
    if (!id || !filter) return;

    fetchCampaignMetrics({ id });
    // eslint-disable-next-line
  }, [id, filterDates, filter]);

  return (
    <Fragment>
      {/* Statistics */}
      <div className="w-full h-full mb-3 bg-white border rounded-sm border-thBorder">
        {/* header */}
        <div className="px-6 w-full flex flex-wrap items-center">
          <div className="w-1/2 inline-flex items-center py-5 2md:w-3/12">
            <h1 className="mr-5 text-2xl">Statistics Overview</h1>
          </div>
          <div className="w-1/2 2md:w-9/12">
            <div className="inline-flex items-center float-right space-x-5">
              <nav className="2md:bg-lightDarkGray 2md:rounded-full 2md:py-1 2md:px-1 relative">
                {filters.map((obj, index) => {
                  return (
                    <FilterSelector
                      key={`filters-${index}`}
                      isActive={filter === obj.value}
                      onClick={() => setFilter(obj.value)}
                    >
                      {obj.label}
                    </FilterSelector>
                  );
                })}
              </nav>
              <div className="w-72">
                <Select
                  placeholder="Filter"
                  value={_.find(dateOptions, ["value", filterValue])?.label}
                  options={dateOptions}
                  onChange={({ value, dates }) => {
                    setFilterValue(value);
                    setFilterDates({
                      start: dates.start,
                      end: dates.end,
                    });
                  }}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        </div>
        {/* header */}
      </div>
      <div className="w-full px-px my-px overflow-hidden">
        <div className="my-2 grid grid-cols-1 md:grid-cols-3 gap-2">
          {metrics.map((metric, metricIndex) => (
            <StatisticsDashboard
              key={`dashboard-metrics-${metricIndex}`}
              icon={metric.icon}
              total={metric.total}
              title={metric.title}
              message={_.find(dateOptions, ["value", filterValue])?.label}
            />
          ))}
        </div>
      </div>
      {/*  */}
      <Card padding="" className="w-full border border-thBorder mb-2" height="">
        <div className="w-full grid 5md:grid-cols-2 divide-y 5md:divide-x 5md:divide-y-0 divide-thBorder">
          <div className="p-8">
            <p className="text-xl leading-7.5 tracking-normalwide">
              Top Interactions
            </p>
            <div className="w-full mt-2">
              <TopEvents dataSource={rawInteractions} />
            </div>
          </div>
          <div className="p-8">
            <p className="text-xl leading-7.5 tracking-normalwide">
              Top Performing Popouts
            </p>
            <div className="w-full mt-2">
              <PerformingPopouts dataSource={rawPopups} />
            </div>
          </div>
        </div>
      </Card>
      {/*  */}
      <div className="w-full mt-2">
        <LeadGenerated labels={labels} datasets={datasets} />
      </div>
    </Fragment>
  );
}
