import BulbIconSVG from "assets/svg/bulb-icon";
import LoadingIndicator from "assets/svg/loading-indicator";
import Button from "components/button/button";
import ButtonColors from "components/button/button-colors";
import ButtonCornerStyles from "components/button/button-corner-styles";
import FileUpload from "components/file-upload/file-upload";
import { Context } from "context/context";
import _ from "lodash";
import { useContext, useRef, useState } from "react";
import toast from "react-hot-toast";
import { CORNER_STYLES } from "utils/constant";
import { useDialogHook } from "utils/customhooks";
import Toggle from "../../toggle/toggle";
import ColorPickerModal from "../color-picker-modal";
import CampaignPopupColorPicker from "../popup/campaign-popup-color-picker";

export default function CampaignStylesPopout() {
  const colorP = useRef();
  const colorTFC = useRef();
  const colorB = useRef();
  const colorBFC = useRef();
  const colorE = useRef();
  const colorETC = useRef();
  const { http } = global.services;
  const { popup, setPopup, setSelectedTab, tabs } = useContext(Context);
  const [cornerStyle, setCornerStyle] = useState(popup.corner_style);
  const [iconBtnStyle, setIconBtnStyle] = useState(popup.button_corner_style);
  const [primaryColors, setPrimaryColors] = useState(
    popup.primary_colour ? [popup.primary_colour] : ["#FFFFF"]
  );
  const [tabFontColors, setTabFontColors] = useState(
    popup.tab_font_colour ? [popup.tab_font_colour] : ["#000000"]
  );
  const [btnColors, setBtnColors] = useState(
    popup.button_colour ? [popup.button_colour] : ["#000000"]
  );
  const [btnFontColors, setBtnFontColors] = useState(
    popup.button_font_colour ? [popup.button_font_colour] : ["#FFFFFF"]
  );
  const [elementColors, setElementColors] = useState(
    popup.element_colour ? [popup.element_colour] : ["#000000"]
  );
  const [elementTextColors, setElementTextColors] = useState(
    popup.element_text_colour ? [popup.element_text_colour] : ["#FFFFFF"]
  );
  const [state, setState] = useState({
    loading: false,
    validationErrors: null,
  });

  const [miniTab, setMiniTab] = useState(
    popup.options?.tab?.icon?.display === "mini"
  );

  const [image, setImage] = useState({
    uploading: false,
    id: null,
    image_url: null,
    image_key: null,
  });

  //modal
  const modal = useDialogHook(ColorPickerModal);
  //end modal

  const onSelectCornerStyle = (value) => {
    setCornerStyle(value);
    //handle Save
    handleOnSave({ corner_style: value });
  };

  const onSelectIconButtonStyle = (value) => {
    setIconBtnStyle(value);
    //handle Save
    handleOnSave({ button_corner_style: value });
  };

  const onMiniTabChange = (isMini = false) => {
    setMiniTab(isMini);
    const display = isMini ? "mini" : "stack";
    handleOnMiniTabChange({ display: display });
  };

  const onSelectColor = (value, name) => {
    setPopup((prev) => ({ ...prev, [name]: value }));
    if (popup[name] === value && name === "primary_colour") {
      colorP.current.toggle();
    } else if (popup[name] === value && name === "button_colour") {
      colorB.current.toggle();
    } else if (popup[name] === value && name === "tab_font_colour") {
      colorTFC.current.toggle();
    } else if (popup[name] === value && name === "button_font_colour") {
      colorBFC.current.toggle();
    } else if (popup[name] === value && name === "element_colour") {
      colorE.current.toggle();
    } else if (popup[name] === value && name === "element_text_colour") {
      colorETC.current.toggle();
    } else {
      handleOnSave({ [name]: value });
    }
  };

  const onAddColors = (value, el) => {
    let payload = _.clone(btnColors);
    if (el === "primary_colour") {
      payload = _.clone(primaryColors);
    } else if (el === "tab_font_colour") {
      payload = _.clone(tabFontColors);
    } else if (el === "button_font_colour") {
      payload = _.clone(btnFontColors);
    } else if (el === "element_colour") {
      payload = _.clone(elementColors);
    } else if (el === "element_text_colour") {
      payload = _.clone(elementTextColors);
    }
    //check if colors exists
    if (!payload.includes(value)) {
      payload.push(value);
    }
    if (_.size(payload) > 6) {
      //limit to 6 colors only
      payload.shift();
    }
    let index = _.size(payload) - 1;
    setPopup((prev) => ({ ...prev, [el]: payload[index] }));
    //set in state
    if (el === "primary_colour") {
      setPrimaryColors(payload);
    } else if (el === "tab_font_colour") {
      setTabFontColors(payload);
    } else if (el === "button_font_colour") {
      setBtnFontColors(payload);
    } else if (el === "element_colour") {
      setElementColors(payload);
    } else if (el === "element_text_colour") {
      setElementTextColors(payload);
    } else {
      setBtnColors(payload);
    }
    //select colors automatic
    onSelectColor(payload[index], el);
  };

  const handleOnSave = async (data = {}) => {
    setState((prev) => ({ ...prev, loading: true }));
    const params = {
      ...popup,
      ...data,
    };
    try {
      if (popup.id) {
        const updateCampaign = await http.put(`popups/${popup?.id}`, params);
        if (updateCampaign) {
          setPopup((prev) => ({ ...prev, ...updateCampaign?.data?.data }));
        }
      }
    } catch (error) {
      if (error.status === 422)
        setState((prev) => ({
          ...prev,
          validationErrors: error.data.data,
        }));
      toast.error("It appears that something went wrong");
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleOnMiniTabChange = async (data = {}) => {
    const params = {
      ...data,
    };

    try {
      if (popup.id) {
        const setPopoutTab = await http.patch(
          `popups/${popup?.id}/set-tab-mobile-display`,
          params
        );
        if (setPopoutTab) {
          setPopup((prev) => ({ ...prev, ...setPopoutTab?.data?.data }));
        }
      }
    } catch (error) {
      if (error.status === 422)
        setState((prev) => ({
          ...prev,
          validationErrors: error.data.data,
        }));
      toast.error("It appears that something went wrong");
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const onUploadThumbnail = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string
        modal({ file: reader.result }, (colors) => {
          if (colors) {
            //remove colors
            setPrimaryColors([popup.primary_colour]);
            setBtnColors([popup.button_colour]);
            setTabFontColors([popup.tab_font_colour]);
            setBtnFontColors([popup.button_font_colour]);
            setElementColors([popup.element_colour]);
            setElementTextColors([popup.element_text_colours]);
            //set image url
            setImage((prev) => ({
              ...prev,
              image_url: reader.result,
            }));
            if (!_.isEmpty(colors)) {
              //select the first color automatically
              if (!popup.button_colour) {
                onSelectColor(colors[0], "button_colour");
              }
              if (!popup.primary_colour) {
                onSelectColor(colors[0], "primary_colour");
              }
              if (!popup.element_colour) {
                onSelectColor(colors[0], "element_colour");
              }
              //end: select the first color automatically
              setPrimaryColors(colors);
              setBtnColors(colors);
              setTabFontColors(colors);
              setBtnFontColors(colors);
              setElementColors(colors);
              setElementTextColors(colors);
            }
          }
        });
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onRemoveImage = () => {
    setImage((prev) => ({
      ...prev,
      image_url: null,
    }));
  };

  const handleChangeStep = () => {
    setSelectedTab("effects");
  };

  return (
    <div className="w-full">
      <div className="w-full">
        <p className="font-medium text-2xl">Choose Your Style</p>
      </div>
      <div className="w-full pt-5">
        {/* left */}
        <div className="relative campaign-left-max">
          <div
            className="bg-white rounded-xl relative"
            style={{ height: "fit-content" }}
          >
            <div className="p-5">
              {/* first */}
              <div className="flex pl-1 pb-2">
                <p className="text-xl mt-1">Style your tab and popout</p>
              </div>
              {/* Corner Style */}
              <div className="w-full pl-1 pt-2 flex justify-between flex-wrap">
                {tabs === "layout" && (
                  <div className="mb-3">
                    <p>Tab Corner Style</p>
                    <div className="flex pt-3 space-x-3">
                      {CORNER_STYLES.map((obj, index) => (
                        <ButtonCornerStyles
                          key={index}
                          Icon={obj.icon}
                          selected={obj.type === cornerStyle}
                          onClick={() => onSelectCornerStyle(obj.type)}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {(popup.contact_method_type !== "multiple-addresses" ||
                  popup.contact_method_type !== "calendy-event-scheduler") && (
                  <div>
                    <p>Button Corner Style</p>
                    <div className="flex pt-3 space-x-3">
                      {CORNER_STYLES.map((obj, index) => (
                        <ButtonCornerStyles
                          key={index}
                          Icon={obj.icon}
                          selected={obj.type === iconBtnStyle}
                          onClick={() => onSelectIconButtonStyle(obj.type)}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {/*  Headings & Button Font */}
              {/* Colors */}
              <div className="pt-5">
                <p className="pl-1">Colors</p>
                <FileUpload
                  className="bg-default rounded-full flex py-2 px-4 justify-center text-sm mb-3"
                  leftContainerClass="thumbnail-view flex ml-1"
                  leftContentClass="w-full rounded-md"
                  imageClass="thumbnail-view object-cover"
                  title="Upload and Extract colors from image"
                  wrapperClass="mt-3"
                  rightContainerClass="px-6 py-1"
                  rightContentClass="mt-1"
                  accept="image/*" // default: null, files to accept
                  width={"70%"} // default: 100%, width for right section label
                  image_url={image.image_url} // default: null, image fetched when uploaded to server
                  uploading={image.uploading} // default: false, upload loader
                  onChange={onUploadThumbnail}
                  removable
                  onRemoveImage={onRemoveImage}
                  name="styles-upload"
                  id="styles-upload"
                />
                {/* primary and button colors */}
                <div className="flex flex-row">
                  {tabs === "layout" && (
                    <div className="text-tipsColor w-1/2">
                      <p className="mb-1.5">Tab Color</p>
                      {primaryColors.map((hex, index) => (
                        <ButtonColors
                          key={index}
                          hex={hex}
                          selected={hex === popup.primary_colour}
                          onClick={() => onSelectColor(hex, "primary_colour")}
                        />
                      ))}
                      <CampaignPopupColorPicker
                        colorRef={colorP}
                        defaultValue={"#09112F"}
                        primaryColor={_.find(primaryColors, function (c) {
                          return c === popup.primary_colour;
                        })}
                        name="primary_colour"
                        addColor={(e) => onAddColors(e, "primary_colour")}
                      />
                    </div>
                  )}
                  <div className="text-tipsColor  w-1/2">
                    <p className="mb-1.5">Icon & Button Color</p>
                    {btnColors.map((hex, index) => (
                      <ButtonColors
                        key={index}
                        hex={hex}
                        selected={hex === popup.button_colour}
                        onClick={() => onSelectColor(hex, "button_colour")}
                      />
                    ))}
                    <CampaignPopupColorPicker
                      colorRef={colorB}
                      defaultValue={"#6FCF97"}
                      name="button_colour"
                      buttonColor={_.find(btnColors, function (c) {
                        return c === popup.button_colour;
                      })}
                      addColor={(e) => onAddColors(e, "button_colour")}
                    />
                  </div>
                  {/* show button color if image is not empty */}
                  {tabs === "image" && (
                    <div className="text-tipsColor w-1/2">
                      <p className="mb-1.5">Button Text Color</p>
                      {btnFontColors.map((hex, index) => (
                        <ButtonColors
                          key={index}
                          hex={hex}
                          selected={hex === popup.button_font_colour}
                          onClick={() =>
                            onSelectColor(hex, "button_font_colour")
                          }
                        />
                      ))}
                      <CampaignPopupColorPicker
                        colorRef={colorBFC}
                        defaultValue={"#FFFFFF"}
                        name="button_font_colour"
                        buttonColor={_.find(btnFontColors, function (c) {
                          return c === popup.button_font_colour;
                        })}
                        addColor={(e) => onAddColors(e, "button_font_colour")}
                      />
                    </div>
                  )}
                </div>
                {/* tab font color and button font colors */}
                <div className="flex flex-row mt-10">
                  {tabs === "layout" && (
                    <>
                      <div className="text-tipsColor w-1/2">
                        <p className="mb-1.5">Text Color</p>
                        {tabFontColors.map((hex, index) => (
                          <ButtonColors
                            key={index}
                            hex={hex}
                            selected={hex === popup.tab_font_colour}
                            onClick={() =>
                              onSelectColor(hex, "tab_font_colour")
                            }
                          />
                        ))}
                        <CampaignPopupColorPicker
                          position="right bottom"
                          colorRef={colorTFC}
                          defaultValue={"#000000"}
                          primaryColor={_.find(tabFontColors, function (c) {
                            return c === popup.tab_font_colour;
                          })}
                          name="tab_font_colour"
                          addColor={(e) => onAddColors(e, "tab_font_colour")}
                        />
                      </div>
                      {/* button font color */}
                      <div className="text-tipsColor w-1/2">
                        <p className="mb-1.5">Button Text Color</p>
                        {btnFontColors.map((hex, index) => (
                          <ButtonColors
                            key={index}
                            hex={hex}
                            selected={hex === popup.button_font_colour}
                            onClick={() =>
                              onSelectColor(hex, "button_font_colour")
                            }
                          />
                        ))}
                        <CampaignPopupColorPicker
                          position="right bottom"
                          colorRef={colorBFC}
                          defaultValue={"#FFFFFF"}
                          name="button_font_colour"
                          buttonColor={_.find(btnFontColors, function (c) {
                            return c === popup.button_font_colour;
                          })}
                          addColor={(e) => onAddColors(e, "button_font_colour")}
                        />
                      </div>
                    </>
                  )}
                </div>
                {/* tab font color and button font colors */}
                {popup?.template_type === "coupons" && (
                  <>
                    <div className="flex flex-row mt-10">
                      <div className="text-tipsColor w-1/2">
                        <p className="mb-1.5">Coupons Color</p>
                        {elementColors.map((hex, index) => (
                          <ButtonColors
                            key={index}
                            hex={hex}
                            selected={hex === popup.element_colour}
                            onClick={() => onSelectColor(hex, "element_colour")}
                          />
                        ))}
                        <CampaignPopupColorPicker
                          position="right bottom"
                          colorRef={colorE}
                          defaultValue={"#000000"}
                          primaryColor={_.find(elementColors, function (c) {
                            return c === popup.element_colour;
                          })}
                          name="element_colour"
                          addColor={(e) => onAddColors(e, "element_colour")}
                        />
                      </div>
                      {/* button font color */}
                      <div className="text-tipsColor w-1/2">
                        <p className="mb-1.5">Coupons Button Text Color</p>
                        {elementTextColors.map((hex, index) => (
                          <ButtonColors
                            key={index}
                            hex={hex}
                            selected={hex === popup.element_text_colour}
                            onClick={() =>
                              onSelectColor(hex, "element_text_colour")
                            }
                          />
                        ))}
                        <CampaignPopupColorPicker
                          position="right bottom"
                          colorRef={colorETC}
                          defaultValue={"#FFFFFF"}
                          name="element_text_colour"
                          buttonColor={_.find(elementTextColors, function (c) {
                            return c === popup.element_text_colour;
                          })}
                          addColor={(e) =>
                            onAddColors(e, "element_text_colour")
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="pt-5">
                <p className="mb-1.5">Tab Layout (in Mobile)</p>
                <div className="w-full my-3 flex items-center" key="mini">
                  <Toggle
                    className="w-10 h-5"
                    hActiveClass="w-3 h-3 ml-auto mr-1 rounded-full bg-white"
                    hInactiveClass="w-3 h-3 ml-1 rounded-full bg-white"
                    checked={miniTab}
                    onChange={() => onMiniTabChange(!miniTab)}
                  />
                  <p className="ml-3">Mini Tab</p>
                </div>
              </div>
            </div>
            {/* footer */}
            <div className="border-t border-tabBorder">
              <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
                <Button
                  buttonName="PREVIOUS"
                  buttonClass="bg-transparent rounded-full py-3"
                  buttonType="transparent"
                  buttonTextClass="text-sm font-bold"
                  onClick={() => setSelectedTab("contactD")}
                />
                <Button
                  buttonName="NEXT"
                  buttonClass="bg-primary rounded-full py-3 px-12 relative"
                  buttonType="primary"
                  onClick={handleChangeStep}
                  disabled={state.loading}
                  icon={
                    <div className="absolute right-2 bottom-0 top-0 flex items-center">
                      {state.loading && (
                        <LoadingIndicator className="text-white w-4 h-4" />
                      )}
                    </div>
                  }
                />
              </div>
            </div>
          </div>
          {/* pro tips */}
          <div className="bg-white rounded-xl relative mt-6">
            <div className="w-full p-5">
              <div className="border-b border-tabBorder">
                <div className="flex pl-2 pb-3">
                  <div className="text-secondary">
                    <BulbIconSVG />
                  </div>
                  <p className="text-sm font-bold pl-4">PRO TIPS</p>
                </div>
              </div>
              <div className="p-5">
                <ul className="list-disc text-tipsColor">
                  <li className="py-1">
                    Try to match the existing look and feel of your website.
                  </li>
                  <li className="py-1">
                    If you already know your brand's primary colour, use it!
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
