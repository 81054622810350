import Checkbox from "components/checkbox/checkbox";
import InputField from "components/input/input";
import WYSIWYGEditorDraft, {
  convertToEditorState,
} from "components/wysiwyg/wysiwyg-editor";
import { useState } from "react";
import { sanitizeValueHTML } from "utils/constant";

const FIELDS = {
  enabled: {
    type: "boolean",
    label: "Enabled",
  },
  title: {
    type: "text",
    label: "Title",
    maxLength: 100,
    required: true,
    placeholder: "50% Off",
  },
  subtitle: {
    type: "text",
    label: "Subtitle",
    maxLength: 200,
    required: false,
    placeholder: "Your next purchase",
  },
  description: {
    type: "wysiwyg",
    label: "Description",
    required: true,
    placeholder: "Coupon cannot be used in conjunction with any other offer.",
  },
  code: {
    type: "text",
    label: "Code",
    maxLength: 20,
    required: false,
    placeholder: "50OFF",
  },
  expiryText: {
    type: "text",
    label: "Expiry Details",
    maxLength: 100,
    required: false,
    placeholder: "Expires on 01/01/20XX",
  },
  termsText: {
    type: "text",
    label: "Terms and Conditions Label",
    maxLength: 100,
    required: false,
    placeholder: "Terms and Conditions Apply",
  },
  termsLink: {
    type: "text",
    label: "Terms and Conditions URL",
    maxLength: 500,
    required: false,
    placeholder: "https://www.example.com/terms",
  },
};

function WYSIWYGField({
  placeholder,
  onChange,
  onBlur,
  errorMessage,
  maxLength,
  label,
  value,
}) {
  const [editorState, setEditorState] = useState(convertToEditorState(value));
  return (
    <div className="input-container">
      <p className="pl-1 pb-2">{label}</p>
      <WYSIWYGEditorDraft
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        errorMessage={errorMessage}
        showCounter={true}
        maxLength={maxLength}
        editorState={editorState}
        setEditorState={setEditorState}
      />
    </div>
  );
}

export default function CouponsDataFields({
  index,
  handleChangeText,
  handleOnBlur,
  value,
  validationErrors,
}) {
  return (
    <>
      {Object.entries(FIELDS).map(([name, params]) => {
        switch (params.type) {
          case "boolean":
            return (
              <Checkbox
                key={`${index}-${name}`}
                name={`${index}-${name}`}
                checked={!!value?.[name]}
                label={params.label}
                onChange={(e) =>
                  handleChangeText(!!e?.target?.checked, index, name)
                }
                containerStyle={{
                  padding: "1rem 0.25rem",
                }}
              />
            );
          case "text":
            return (
              <InputField
                key={`${index}-${name}`}
                inputContainerClass="input-container"
                labelClass="input-label-xs"
                paddingTop="1.5rem"
                value={value?.[name] || ""}
                maxLength={params.maxLength || 100}
                label={params.label}
                name={`${index}-${name}`}
                placeholder={params.placeholder}
                errorMessage={validationErrors?.coupons?.[index]?.[name]}
                type="text"
                onChange={(e) =>
                  handleChangeText(e?.target?.value, index, name)
                }
                onBlur={handleOnBlur}
              />
            );
          case "wysiwyg":
            return (
              <WYSIWYGField
                key={`${index}-${name}`}
                value={value?.[name] || ""}
                maxLength={params.maxLength || 300}
                label={params.label}
                placeholder={params.placeholder}
                errorMessage={validationErrors?.coupons?.[index]?.[name]}
                onChange={(value) =>
                  handleChangeText(sanitizeValueHTML(value), index, name)
                }
                onBlur={handleOnBlur}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
}
