import LoadingIndicator from "assets/svg/loading-indicator";
import Button from "components/button/button";
import InputRange from "components/input-range/input-range";
import InputField from "components/input/input";
import WYSIWYGEditorDraft, {
  convertToEditorState,
} from "components/wysiwyg/wysiwyg-editor";
import { Context } from "context/context";
import update from "immutability-helper";
import _ from "lodash";
import { useContext, useMemo, useState } from "react";
import {
  MAX_TITLE_LENGTH,
  TEMPLATEOBJ,
  TITLESUGGESTIONS,
  sanitizeValueHTML,
} from "utils/constant";
import SuggestionButton from "../component/suggestions-btn";
import CouponsDataFields from "./coupon-fields";

export default function CouponsData({
  handleChangeStep = () => console.log("We are missing a function here"),
  handleOnSave = () => {},
  loading = false,
  validationErrors = null,
  setState,
}) {
  const { setSelectedTab, popup, setPopup } = useContext(Context); // context
  const initials = useMemo(() => {
    if (_.isEmpty(popup?.template_content)) {
      return _.find(TEMPLATEOBJ, { type: "coupons" });
    } else {
      return JSON.parse(popup?.template_content);
    }
  }, [popup.template_content]);
  // start wysiwyg
  const titleInitials = useMemo(() => {
    let data = {};
    if (_.isEmpty(popup?.template_content)) {
      data = _.find(TEMPLATEOBJ, { type: "coupons" });
    } else {
      data = JSON.parse(popup?.template_content);
    }
    return convertToEditorState(data.title || "");
  }, [popup.template_content]);
  const [editorState, setEditorState] = useState(titleInitials);
  // end wysiwyg
  const [suggestion, setSuggestion] = useState(0);
  const [object, setObject] = useState(initials);

  const handleChangeValue = (value, element) => {
    let payload = _.clone(object);
    let finalValue = value;
    let data = payload;
    if (element === "title") {
      if (_.isEmpty(_.trim(value.replace(/(<([^>]+)>)/gi, "")))) {
        finalValue = "";
        data = update(payload, {
          [element]: { $set: finalValue },
          title_decoration: { $set: "normal" },
        });
      } else {
        data = update(payload, {
          [element]: { $set: sanitizeValueHTML(finalValue) },
          title_decoration: { $set: "normal" },
        });
      }
    } else {
      data = update(payload, {
        [element]: { $set: finalValue },
      });
    }
    setObject(data);
    //set to popout
    if (element === "title") {
      setPopup((prev) => ({
        ...prev,
        template_content: JSON.stringify(data),
      }));
    }
  };

  const handleChangeText = (value, index, element) => {
    let payload = _.clone(object);
    let data = update(payload, {
      coupons: {
        [index]: {
          [element]: { $set: value },
        },
      },
    });
    setObject(data);
    if (element === "enabled") {
      handleOnSave(data);
    }
  };

  const handleOnBlur = (e) => {
    handleOnSave(object);
  };

  const validateBeforeNext = () => {
    let payload = _.clone(validationErrors);

    if (_.isEmpty(object?.title)) {
      payload.title = "Title is required";
    }

    payload.coupons = [];
    let hasError = false;
    object?.coupons?.forEach((c, idx) => {
      payload.coupons[idx] = {};
      if (c.enabled && _.isEmpty(c.title)) {
        payload.coupons[idx].title = "Title is required";
        hasError = true;
      }
    });
    if (!hasError) {
      delete payload.coupons;
    }

    setState((prev) => ({
      ...prev,
      validationErrors: {
        ...prev.validationErrors,
        ...payload,
      },
    }));
    if (_.isEmpty(payload)) {
      handleChangeStep();
    }
  };

  //suggesstions
  const handleSuggestion = (type) => {
    const title = TITLESUGGESTIONS;
    const size = _.size(TITLESUGGESTIONS) - 1;
    let value = null;
    if (type === "next") {
      if (suggestion > size) {
        setSuggestion(1);
        value = title[0];
      } else {
        value = title[suggestion];
        setSuggestion((prev) => prev + 1);
      }
    } else {
      let counter = suggestion - 2;
      if (suggestion === 0 || suggestion === 1) {
        value = title[size];
        setSuggestion(size);
      } else {
        if (counter > 0) {
          value = title[counter];
          setSuggestion((prev) => prev - 1);
        } else if (counter === 0) {
          value = title[counter];
          setSuggestion(size + 2);
        }
      }
    }
    if (value) {
      let payload = _.clone(object);
      let data = update(payload, {
        title: { $set: value },
      });
      //handle set
      setEditorState(convertToEditorState(value));
      setObject(data);
      handleOnSave(data);
    }
  };

  return (
    <>
      {/* header */}
      <div className="w-full">
        <p className="font-medium text-2xl">Add Your Content</p>
      </div>
      {/* end header */}
      <div className="w-full pt-5">
        {/* left card start */}
        <div
          className="bg-white rounded-xl relative campaign-left-max"
          style={{ height: "fit-content" }}
        >
          <div className="p-5">
            {/* first */}
            <div>
              <p className="text-xl pl-1 pb-2">Enter your coupon details</p>
            </div>
            <div className="w-full mb-2">
              <p className="pl-1 pt-2 pb-2">Type your heading here:</p>
              <WYSIWYGEditorDraft
                placeholder={`e.g. Excepteur sint occaecat cupidatat non proident, sunt in cul...`}
                onChange={(value) => {
                  handleChangeValue(value, "title");
                }}
                onBlur={handleOnBlur}
                errorMessage={validationErrors?.title}
                showCounter={true}
                maxLength={MAX_TITLE_LENGTH}
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </div>
            {/* title_decoration */}
            <div className="w-full flex items-center 5md:block 3lg:flex 3lg:items-center justify-start">
              <SuggestionButton onClick={(e) => handleSuggestion(e)} />
            </div>
            {/* icons */}
            <p className="pl-1 pt-4 pb-2">
              Enable and complete at least 1 coupon:
            </p>
            <div className="mt-3">
              {((object && object.coupons) || [0, 1, 2, 3]).map(
                (value, index) => {
                  return (
                    <div key={index} className="pb-5">
                      <p className="pl-1 text-lg">Coupon {index + 1}</p>
                      <CouponsDataFields
                        index={index}
                        handleChangeText={handleChangeText}
                        handleOnBlur={handleOnBlur}
                        value={value}
                        validationErrors={validationErrors}
                      />
                    </div>
                  );
                }
              )}
            </div>
            <div className="mt-3">
              <p className="pl-1 text-lg">Testimonial (Optional)</p>
              <div className="mb-2 p-1">
                <InputRange
                  label="Star Rating (Out Of 5)"
                  name="rating"
                  defaultValue={object?.testimonialRating}
                  onChange={(e) =>
                    handleChangeValue(
                      parseFloat(e?.target?.value),
                      "testimonialRating"
                    )
                  }
                />
              </div>
              <InputField
                inputContainerClass="input-container"
                labelClass="input-label-xs"
                paddingTop="1.5rem"
                value={object.testimonialContent}
                maxLength={300}
                label="Testimonial Content"
                name="testimonialContent"
                placeholder="“Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.”"
                errorMessage={validationErrors?.testimonialContent}
                type="text"
                onChange={(e) =>
                  handleChangeValue(e?.target?.value, "testimonialContent")
                }
                onBlur={handleOnBlur}
              />
              <InputField
                inputContainerClass="input-container"
                labelClass="input-label-xs"
                paddingTop="1.5rem"
                value={object.testimonialAuthor}
                maxLength={100}
                label="Testimonial Author"
                name="testimonialAuthor"
                placeholder="Jane Doe - Sample Business"
                errorMessage={validationErrors?.testimonialAuthor}
                type="text"
                onChange={(e) =>
                  handleChangeValue(e?.target?.value, "testimonialAuthor")
                }
                onBlur={handleOnBlur}
              />
            </div>
          </div>
          {/* footer */}
          <div className="border-t border-tabBorder">
            <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
              <Button
                buttonName="PREVIOUS"
                buttonClass="bg-transparent rounded-full py-3"
                buttonType="transparent"
                buttonTextClass="text-sm font-bold"
                onClick={() => setSelectedTab("template")}
              />
              <Button
                buttonName="NEXT"
                buttonClass="relative bg-primary rounded-full py-3 px-12"
                buttonType="primary"
                onClick={validateBeforeNext}
                disabled={loading}
                icon={
                  <div className="absolute right-2 bottom-0 top-0 flex items-center">
                    {loading && (
                      <LoadingIndicator className="text-white w-4 h-4" />
                    )}
                  </div>
                }
              />
            </div>
          </div>
        </div>
        {/* end card start */}
      </div>
    </>
  );
}
