export default function HubspotFormsIcon({ selected }) {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={selected ? "1" : "0.5"}>
        <path
          d="M472 16H40C33.637 16.0073 27.5368 18.5382 23.0375 23.0375C18.5382 27.5368 16.0073 33.637 16 40V472C16.0073 478.363 18.5382 484.463 23.0375 488.963C27.5368 493.462 33.637 495.993 40 496H472C478.363 495.993 484.463 493.462 488.963 488.963C493.462 484.463 495.993 478.363 496 472V40C495.993 33.637 493.462 27.5368 488.963 23.0375C484.463 18.5382 478.363 16.0073 472 16ZM480 48L285.5 52L288.5 56L286.5 60L480 64V472C479.998 474.121 479.154 476.154 477.654 477.654C476.154 479.154 474.121 479.998 472 480H40C37.879 479.998 35.8456 479.154 34.3458 477.654C32.8461 476.154 32.0024 474.121 32 472V40C32.0024 37.879 32.8461 35.8456 34.3458 34.3458C35.8456 32.8461 37.879 32.0024 40 32H472C474.121 32.0024 476.154 32.8461 477.654 34.3458C479.154 35.8456 479.998 37.879 480 40V48Z"
          fill="#343C4E"
        />
        <path d="M262 96H448V112H262V96Z" fill="#343C4E" />
        <path d="M262 144H448V160H262V144Z" fill="#343C4E" />
        <path d="M262 48H480V64H262V48Z" fill="#E6572A" />
        <path
          d="M64 275V323C64 325.122 64.8429 327.157 66.3432 328.657C67.8435 330.157 69.8783 331 72 331H312V315H80V283H432V315H408V331H440C442.122 331 444.157 330.157 445.657 328.657C447.157 327.157 448 325.122 448 323V275C448 272.878 447.157 270.843 445.657 269.343C444.157 267.843 442.122 267 440 267H72C69.8783 267 67.8435 267.843 66.3432 269.343C64.8429 270.843 64 272.878 64 275Z"
          fill="#E6572A"
        />
        <path
          d="M64 443V395C64 392.878 64.8429 390.843 66.3432 389.343C67.8435 387.843 69.8783 387 72 387H294V403H80V435H432V403H408V387H440C442.122 387 444.157 387.843 445.657 389.343C447.157 390.843 448 392.878 448 395V443C448 445.122 447.157 447.157 445.657 448.657C444.157 450.157 442.122 451 440 451H72C69.8783 451 67.8435 450.157 66.3432 448.657C64.8429 447.157 64 445.122 64 443Z"
          fill="#E6572A"
        />
        <path d="M64 235H136V251H64V235Z" fill="#343C4E" />
        <path d="M64 355H176V371H64V355Z" fill="#343C4E" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M340.295 324.703L328.772 373.826L336.732 369.234C341.852 366.28 348.399 368.67 350.411 374.228L368.1 423.082L382.26 417.89L365.374 368.921C363.423 363.263 367.127 357.231 373.057 356.413L380.589 355.375L340.295 324.703ZM326.385 309.56C327.957 302.859 335.912 300.002 341.388 304.171L405.246 352.779C411.973 357.899 409.164 368.594 400.791 369.749L384.42 372.007L400.729 419.302C402.416 424.194 399.877 429.537 395.018 431.318L366.882 441.635C361.942 443.446 356.47 440.897 354.679 435.95L337.474 388.432L322.261 397.208C315.055 401.366 306.365 394.909 308.265 386.81L326.385 309.56Z"
          fill="#343C4E"
        />
        <path
          d="M212.205 114.649C206.19 110.027 199.119 106.995 191.639 105.832H191.671V85.0791C197.245 82.4602 200.817 76.8424 200.844 70.6539V70.1689C200.821 61.3488 193.719 54.2042 184.953 54.1806H184.47C175.703 54.2042 168.602 61.3488 168.579 70.1689V70.6539C168.606 76.8424 172.178 82.4602 177.752 85.0791V105.876C169.902 107.083 162.508 110.356 156.32 115.363L99.5899 70.9126C102.169 61.1967 96.4314 51.2166 86.7742 48.6213C77.1169 46.026 67.1971 51.7986 64.6175 61.5146C62.038 71.2305 67.7757 81.2106 77.4332 83.8059C82.0509 85.0466 86.9685 84.3999 91.1136 82.006L146.89 125.691C136.608 141.297 136.884 161.648 147.586 176.965L130.612 194.042C129.249 193.606 127.831 193.373 126.401 193.352C118.263 193.352 111.667 199.989 111.667 208.176C111.667 216.363 118.263 223 126.401 223C134.538 223 141.135 216.363 141.135 208.176C141.115 206.738 140.884 205.31 140.449 203.939L157.241 187.045C177.112 202.315 205.524 198.488 220.703 178.496C235.88 158.505 232.076 129.919 212.205 114.649ZM184.738 174.216L184.685 174.205C171.854 174.175 161.476 163.687 161.506 150.778C161.536 137.869 171.961 127.429 184.792 127.458C197.606 127.488 207.976 137.951 207.97 150.843C207.97 163.752 197.569 174.216 184.738 174.216Z"
          fill="#E6572A"
        />
      </g>
    </svg>
  );
}
