import BarsIconSVG from "assets/svg/bars-icon";
import ContactsTabIconSVG from "assets/svg/contact-icon";
import { TabSelector } from "components/global/tabselector";
import { Context } from "context/context";
import { useCallback, useContext, useEffect, useRef } from "react";
import CalendyEventPopout from "../templates/contact-method/calendy-event-popout";
import EnquiryPopout from "../templates/contact-method/enquiry-popout";
import HubspotForm from "../templates/contact-method/hubspot-form";
import LeadMagnetPopout from "../templates/contact-method/lead-magnet-popout";
import MultipleAddressesPopout from "../templates/contact-method/multiple-address-popout";
import StandardPopout from "../templates/contact-method/standard-popout";
import CouponsTab from "../templates/coupons-tab";
import PromotionsTab from "../templates/promotions-tab";
import ProofPointsTab from "../templates/proof-points-tab";
import StatisticsDataTab from "../templates/statistics-data-tab";
import TestimonialsTab from "../templates/testimonials-tab";
import VideosTab from "../templates/videos-tab";

export default function PopupExampleMobileTemplate({ preview = false }) {
  const {
    popup,
    mobileTabs,
    setMobileTabs,
    mobileContentOpen,
    setMobileContentOpen,
  } = useContext(Context);

  const keyPointsRef = useRef(null);
  const contactInfoRef = useRef(null);
  const scrollContainerRef = useRef(null);

  const handleScroll = useCallback(() => {
    const container = scrollContainerRef.current;
    if (!container && mobileTabs !== "stack") return;

    const scrollPosition = container.scrollTop;
    const positions = {
      keyPoints: keyPointsRef.current?.offsetTop - container?.offsetTop,
      contactInfo:
        contactInfoRef.current?.offsetTop - container?.offsetTop - 55,
    };

    const currentSection =
      scrollPosition >= positions.contactInfo ? "contact-info" : "key-points";

    if (currentSection && currentSection !== mobileTabs) {
      setMobileTabs(currentSection);
    }
  }, [mobileTabs, setMobileTabs]);

  const changeTab = (sectionKey) => {
    if (popup.mobile_layout !== "stack") {
      setMobileTabs(sectionKey);
      return;
    }

    const section = sectionKey === "key-points" ? keyPointsRef : contactInfoRef;
    const container = scrollContainerRef.current;

    if (section && container) {
      container.scrollTo({
        top: section.current?.offsetTop - container?.offsetTop - 30,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      //changeTab(mobileTabs);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [mobileTabs, handleScroll]);

  const className = preview ? "px-5" : "pt-8 px-6";

  const renderTemplateTypeContent = () => {
    switch (popup.template_type) {
      case "proof-points":
        return <ProofPointsTab popup={popup} />;
      case "testimonials":
        return <TestimonialsTab popup={popup} />;
      case "videos":
        return <VideosTab popup={popup} />;
      case "promotions":
        return <PromotionsTab popup={popup} />;
      case "statistics-data":
        return <StatisticsDataTab popup={popup} />;
      case "coupons":
        return <CouponsTab popup={popup} />;
      default:
        return null;
    }
  };

  const renderContactMethodTypeContent = () => {
    switch (popup.contact_method_type) {
      case "standard-details":
        return <StandardPopout popup={popup} />;
      case "enquiry-form":
        return <EnquiryPopout popup={popup} />;
      case "lead-magnet":
        return <LeadMagnetPopout popup={popup} />;
      case "multiple-addresses":
        return <MultipleAddressesPopout popup={popup} />;
      case "calendy-event-scheduler":
        return <CalendyEventPopout popup={popup} />;
      case "hubspot-form":
        return <HubspotForm popup={popup} />;
      default:
        return null;
    }
  };

  const renderTabContent = () => {
    return (
      <>
        {popup.mobile_layout === "stack" ? (
          <>
            <div ref={keyPointsRef}>{renderTemplateTypeContent()}</div>
            <div ref={contactInfoRef}>{renderContactMethodTypeContent()}</div>
          </>
        ) : (
          <>
            {mobileTabs === "key-points" ? (
              <div ref={keyPointsRef}>{renderTemplateTypeContent()}</div>
            ) : (
              <div ref={contactInfoRef}>{renderContactMethodTypeContent()}</div>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div
      className={`${className} pt-8 animated ${
        mobileContentOpen && "fadeIn"
      } faster ${mobileTabs === "key-points" ? "bg-lightGray" : "white"}`}
    >
      <div className="px-5 w-full flex justify-end">
        <button
          className="flex justify-end"
          onClick={() => setMobileContentOpen(false)}
        >
          <span className="close opacity-100 w-4 h-4 text-right" />
        </button>
      </div>

      <nav className="border-b border-tabBorder">
        <TabSelector
          isActive={mobileTabs === "key-points"}
          onClick={() => changeTab("key-points")}
          colorType="secondary"
          paddingY="py-2"
          gap="mr-3"
        >
          <BarsIconSVG /> <span className="pl-2">KEY POINTS</span>
        </TabSelector>
        <TabSelector
          isActive={mobileTabs === "contact-info"}
          onClick={() => changeTab("contact-info")}
          colorType="secondary"
          paddingY="py-2"
          gap=""
        >
          <ContactsTabIconSVG /> <span className="pl-2">CONTACT INFO</span>
        </TabSelector>
      </nav>
      <div
        ref={scrollContainerRef}
        className="pt-5 px-5 overflow-y-scroll hidden-scrollable-bar"
        style={{ height: preview ? "625px" : "650px" }}
      >
        {renderTabContent()}
      </div>
    </div>
  );
}
