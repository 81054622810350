import { getIcon } from "assets/icons/icons-list";
import ChatsHeartIconSVG from "assets/svg/example/chats-heart-icon";
import ClockIconSVG from "assets/svg/example/clock-icon";
import NotesIconSVG from "assets/svg/example/notes-icon";
import WalletIconSVG from "assets/svg/example/wallet-icon";
import { Context } from "context/context";
import { useContext } from "react";
import HeaderExample from "./component/header";

const NUM = ["+181%", "408%", "+40%", "+70"];
const IconsExample = [
  <WalletIconSVG className="icon-size text-tabBorder" />,
  <ClockIconSVG className="icon-size text-tabBorder" />,
  <NotesIconSVG className="icon-size text-tabBorder" />,
  <ChatsHeartIconSVG className="icon-size text-tabBorder" />,
];

const showDummy = ["template", "content"];

export default function StatisticsDataTab({ popup }) {
  const { view, selectedTab } = useContext(Context);
  const mobile = view === "mobile";
  const show = showDummy.includes(selectedTab);
  const data = popup.template_content && JSON.parse(popup.template_content);

  return (
    <div
      className={!mobile ? "proof-points-tab" : ""}
      style={{
        marginBottom: mobile ? "50px" : "0",
        height: mobile ? "auto" : "635px",
      }}
    >
      <HeaderExample data={data} popup={popup} show={show} />
      {/* icons */}
      <div className="pt-6 grid grid-cols-2 gap-y-8 gap-x-2">
        {/*  */}
        {(data?.stats || []).map((obj, index) => {
          const Icon = getIcon(obj.icon?.id);
          return (
            <div key={index} className={`${!obj.visible && "hidden"}`}>
              <div>
                {Icon ? (
                  <Icon
                    className="icon-size"
                    fill={popup.button_colour ? popup.button_colour : "#000"}
                    sx={{
                      fontSize: 50,
                      color: popup.button_colour ? popup.button_colour : "#000",
                    }}
                  />
                ) : (
                  show && IconsExample[index]
                )}
              </div>
              <div>
                <p
                  className={`text-4xl font-bold pt-2 break-all ${
                    !obj?.data && "text-tabBorder"
                  }`}
                >
                  {obj?.data || (show && NUM[index])}
                </p>
                <p
                  className={`text-10 overflow-hidden ${
                    !obj?.subtext && "text-tabBorder"
                  }`}
                >
                  {obj?.subtext ||
                    (show && "Lorem ipsum dolor sit consectetur adipiscing")}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
