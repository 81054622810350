export default function CouponScissors({ color }) {
  return (
    <svg
      width="27"
      height="19"
      viewBox="0 0 27 19"
      fill="none"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.05925 15.4779C3.05925 15.4779 4.49825 16.7169 5.71125 16.0259C6.64525 15.4889 12.5783 12.5119 15.2253 11.1859L18.9733 13.3289C18.5663 14.0499 18.4983 14.8839 18.7173 15.6879C18.9943 16.7039 19.7273 17.6669 20.8093 18.2859C22.7543 19.4009 25.0923 18.9769 26.0253 17.3389C26.4413 16.6159 26.5093 15.7759 26.2913 14.9659C26.0143 13.9499 25.2813 12.9859 24.1993 12.3639C23.7213 12.0939 23.2233 11.9149 22.7283 11.8229C22.7243 11.8219 22.7203 11.8209 22.7163 11.8189C22.6773 11.8129 22.6323 11.8039 22.5893 11.7989C19.5033 11.3399 17.9253 10.4689 17.1593 9.85493C17.9253 9.23893 19.5033 8.36993 22.5893 7.90993C22.6323 7.90493 22.6773 7.89693 22.7163 7.89093C22.7203 7.88893 22.7243 7.88893 22.7283 7.88693C23.2223 7.79493 23.7203 7.61593 24.1993 7.34493C25.2813 6.72293 26.0133 5.75993 26.2913 4.74293C26.5103 3.93493 26.4413 3.09493 26.0253 2.36793C25.0913 0.732933 22.7543 0.306934 20.8083 1.42193C19.7263 2.04293 18.9943 3.00593 18.7163 4.02393C18.4973 4.82693 18.5663 5.65893 18.9723 6.38093L15.2243 8.52293C12.5783 7.19793 6.64426 4.21793 5.71026 3.68393C4.49726 2.99293 3.05826 4.23093 3.05826 4.23093L12.8963 9.85493L3.05925 15.4779ZM20.5703 14.2209C20.9963 13.4789 22.2403 13.3579 23.2873 13.9569C23.9023 14.3069 24.3653 14.8649 24.5233 15.4449C24.5923 15.7039 24.6373 16.0829 24.4353 16.4299C24.0113 17.1709 22.7673 17.2919 21.7203 16.6939C21.0953 16.3389 20.6463 15.7959 20.4863 15.2059C20.4143 14.9499 20.3733 14.5689 20.5703 14.2209ZM20.4863 4.50493C20.6463 3.91393 21.0953 3.37193 21.7203 3.01593C22.7673 2.41593 24.0113 2.53793 24.4353 3.28093C24.6363 3.62893 24.5913 4.00693 24.5233 4.26293C24.3653 4.84493 23.9023 5.40293 23.2873 5.75493C22.2403 6.35293 20.9963 6.23193 20.5703 5.48893C20.3733 5.13893 20.4143 4.76093 20.4863 4.50493Z"
        fill={color}
        id="path1"
      />
    </svg>
  );
}
