import PersonCircleIconSVG from "assets/svg/person-circle-icon";
import StarRating from "components/rating/star-rating";
import { Context } from "context/context";
import { useContext } from "react";
import { sanitizeValueHTML } from "utils/constant";
import HeaderExample from "./component/header";

const showDummy = ["template", "content"];

export default function TestimonialsTab({ popup }) {
  const { view, selectedTab } = useContext(Context);
  const mobile = view === "mobile";
  const data = popup.template_content && JSON.parse(popup.template_content);
  const show = showDummy.includes(selectedTab);

  return (
    <div
      className={mobile ? "" : "testimonials-tab"}
      style={{
        marginBottom: mobile ? "50px" : "0",
        height: mobile ? "auto" : "635px",
      }}
    >
      <HeaderExample
        data={data}
        popup={popup}
        show={show}
        placeholder="What Our Clients Say About Us"
      />
      <div className="divide-y-2 divide-default">
        {(data?.testimonials || []).map((obj, index) => (
          <div className="pt-5" key={index}>
            {show || obj.rating ? <StarRating value={obj.rating} /> : null}
            <div
              className={`${show || obj.rating ? "pt-3" : ""} text-sm ${
                !obj?.quote && "text-tabBorder"
              }`}
              dangerouslySetInnerHTML={{
                __html:
                  sanitizeValueHTML(obj?.quote) ||
                  (show &&
                    "“Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.”"),
              }}
            />
            <div
              className={`flex ${
                obj?.image_url || show ? "space-x-5" : ""
              } pt-4 pb-5`}
            >
              <div className="rounded-full">
                {obj?.image_url ? (
                  <img
                    src={obj?.image_url}
                    alt={`icon-${obj?.image_url || index}`}
                    className="icon-size object-cover rounded-full"
                  />
                ) : (
                  show && <PersonCircleIconSVG />
                )}
              </div>
              <div className="pt-1">
                <p
                  className={`text-13 font-bold ${
                    !obj?.name && "text-tabBorder"
                  }`}
                >
                  {obj?.name || (show && "John Doe")}
                </p>
                <p className={`text-11 ${!obj?.position && "text-tabBorder"}`}>
                  {obj?.position || (show && "CEO of Open Enterprise")}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
