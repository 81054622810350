import { useMemo } from "react";
import styled from "styled-components";
import { device } from "../../screens";
import ScrollableDiv from "../commons/scrollable";
import Coupons from "./coupons/coupons";
import Promotions from "./promotions";
import ProofPoints from "./proof-points/proof-points";
import Statistics from "./statistics/statistics";
import Testimonials from "./testimonials/testimonials";
import Videos from "./videos";

const TemplateContainer = styled.div`
  padding-left: 21px;
  padding-right: 20px;
  padding-bottom: 50px !important;
  padding-top: 28px !important;

  width: 100%;
  height: 100%;

  @media ${device.xs} {
    padding-left: 46px !important;
    padding-right: 47px !important;
    padding-bottom: 50px !important;
  }

  @media (min-width: 1${({ width }) => width + 1}px) {
    padding-top: 50px !important;
    padding-left: 41px !important;
    padding-right: 40px !important;
    padding-bottom: 50px !important;
  }
`;

const renderTemplate = (data) => {
  const templates = {
    "proof-points": (campaign) => <ProofPoints data={campaign} />,
    testimonials: (campaign) => <Testimonials data={campaign} />,
    videos: (campaign) => <Videos data={campaign} />,
    promotions: (campaign) => <Promotions data={campaign} />,
    "statistics-data": (campaign) => <Statistics data={campaign} />,
    coupons: (campaign) => <Coupons data={campaign} />,
  };

  const template = data.type
    ? templates[data.type]
    : () => {
        return null;
      };

  return template(data);
};

const TemplatePreview = ({
  campaign,
  popup,
  overlay,
  containerWidth = 120,
}) => {
  let payload = useMemo(() => {
    return {
      font: popup?.font,
      button_colour: popup?.button_colour,
      element_colour: popup?.element_colour,
      element_text_color: popup?.element_text_color,
      button_corner_style: popup?.button_corner_style,
      overlay: overlay,
      width: popup?.image ? popup?.tab_image_size : containerWidth,
      ...campaign,
    };
  }, [campaign, popup, overlay, containerWidth]);

  return (
    <ScrollableDiv
      width={popup?.image ? popup?.tab_image_size : containerWidth}
    >
      <TemplateContainer
        width={popup?.image ? popup?.tab_image_size : containerWidth}
      >
        {renderTemplate(payload)}
      </TemplateContainer>
    </ScrollableDiv>
  );
};

export default TemplatePreview;
