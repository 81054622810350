import LinkInputIconSVG from "assets/svg/link-input-icon";
import LoadingIndicator from "assets/svg/loading-indicator";
import Button from "components/button/button";
import FileUpload from "components/file-upload/file-upload";
import InputRange from "components/input-range/input-range";
import Input from "components/input/input";
import WYSIWYGEditorDraft, {
  convertToEditorState,
} from "components/wysiwyg/wysiwyg-editor";
import { Context } from "context/context";
import update from "immutability-helper";
import _ from "lodash";
import { useContext, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { AiFillVideoCamera } from "react-icons/ai";
import ReactPlayer from "react-player";
import {
  MAX_TITLE_LENGTH,
  TEMPLATEOBJ,
  VIDEOSUGGESTIONS,
  sanitizeValueHTML,
} from "utils/constant";
import validator from "validator";
import SuggestionButton from "./component/suggestions-btn";

export default function Videos({
  handleChangeStep = () => console.log("We are missing a function here"),
  handleOnSave = () => {},
  loading = false,
  validationErrors = null,
  setState,
}) {
  const { setSelectedTab, popup, setPopup } = useContext(Context); // context
  const { http } = global.services;
  const initials = useMemo(() => {
    if (_.isEmpty(popup?.template_content)) {
      return _.find(TEMPLATEOBJ, { type: "videos" });
    } else {
      return JSON.parse(popup?.template_content);
    }
  }, [popup.template_content]);
  // start wysiwyg
  const titleInitials = useMemo(() => {
    let data = {};
    if (_.isEmpty(popup?.template_content)) {
      data = _.find(TEMPLATEOBJ, { type: "videos" });
    } else {
      data = JSON.parse(popup?.template_content);
    }
    return convertToEditorState(data.title || "");
  }, [popup.template_content]);
  const [editorState, setEditorState] = useState(titleInitials);
  // end wysiwyg
  const [suggestion, setSuggestion] = useState(0);
  const [object, setObject] = useState(initials);
  const [image, setImage] = useState({
    uploading: false,
    id: null,
    image_key: null,
  });

  const handleChangeValue = (value, element) => {
    let payload = _.clone(object);
    let finalValue = value;
    let data = payload;
    if (element === "title") {
      if (_.isEmpty(_.trim(value.replace(/(<([^>]+)>)/gi, "")))) {
        finalValue = "";
        data = update(payload, {
          [element]: { $set: finalValue },
          title_decoration: { $set: "normal" },
        });
      } else {
        data = update(payload, {
          [element]: { $set: sanitizeValueHTML(finalValue) },
          title_decoration: { $set: "normal" },
        });
      }
    } else {
      data = update(payload, {
        [element]: { $set: finalValue },
      });
    }
    setObject(data);
    //set to popout
    if (element === "title") {
      setPopup((prev) => ({
        ...prev,
        template_content: JSON.stringify(data),
      }));
    }
  };

  const handleChangeVideoLinks = (value, element) => {
    let payload = _.clone(object);
    let data = update(payload, {
      video: {
        [element]: { $set: value },
      },
    });
    setObject(data);
    if (element === "rating") {
      handleOnSave(data);
    }
  };

  const onUploadImage = async (e) => {
    setImage((prevState) => ({ ...prevState, uploading: true }));
    let payload = _.clone(object);
    const formData = new FormData();
    const file = e.target.files[0];

    try {
      if (file.size > 300000) {
        setImage((prevState) => ({ ...prevState, uploading: false }));
        return toast.error("Oops! The file is too large. Max file size: 300KB");
      } else {
        /* Remove old image when attempting to re-upload new image */
        if (image.id && image.image_key) {
          await http.delete(`medias/${image.id}`, {
            key: image.image_key,
          });
        }

        formData.append("file", file);
        formData.append("type", "templates");
        const uploadResult = await http.post("medias", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        const result = uploadResult.data.data;
        if (result) {
          let put = update(payload, {
            //update object
            thumbnail: {
              url: { $set: result.presigned_url },
              media_id: { $set: result.id },
            },
          });
          setObject(put);
          setImage((prev) => ({
            ...prev,
            id: result.id,
            image_key: result.key,
          }));
          //handle save
          handleOnSave(put);
        }
      }
    } catch (error) {
      toast.error(
        "An unknown error occured. Please refresh the page and try again."
      );
    } finally {
      setImage((prevState) => ({ ...prevState, uploading: false }));
      e.target.value = "";
    }
  };

  const handleRemoveImage = async () => {
    setImage((prevState) => ({ ...prevState, uploading: true }));
    let payload = _.clone(object);
    try {
      if (image.id && image.image_key) {
        const removeImageResult = await http.delete(`medias/${image.id}`, {
          key: image.image_key,
        });
        if (removeImageResult.data?.success) {
          let put = update(payload, {
            //update object
            thumbnail: {
              url: { $set: null },
              media_id: { $set: null },
            },
          });
          setObject(put);
          setImage((prev) => ({
            ...prev,
            id: null,
            image_key: null,
          }));
          //handle save
          handleOnSave(put);
          toast.success("Image removed successfully");
        }
      }
    } catch (error) {
      if (error.status === 422) {
        toast.error("It appears that something went wrong");
      } else if (error.status === 404) {
        let put = update(payload, {
          //update object
          thumbnail: {
            url: { $set: null },
            media_id: { $set: null },
          },
        });
        setObject(put);
      }
      setImage((prev) => ({
        ...prev,
        id: null,
        image_key: null,
        uploading: false,
      }));
    } finally {
      setImage((prevState) => ({ ...prevState, uploading: false }));
    }
  };

  const handleOnBlur = () => {
    let payload = _.clone(validationErrors);
    if (object.video?.link) {
      if (validator.isURL(object.video?.link)) {
        let link = object?.video?.link;
        if (ReactPlayer.canPlay(link)) {
          handleOnSave(object);
        } else {
          payload = { ...payload, link: "Video link is invalid" };
          setState((prev) => ({
            ...prev,
            validationErrors: {
              ...prev.validationErrors,
              ...payload,
            },
          }));
        }
      } else {
        payload = { link: "The link format is invalid." };
        setState((prev) => ({
          ...prev,
          validationErrors: {
            ...prev.validationErrors,
            ...payload,
          },
        }));
      }
    } else {
      handleOnSave(object);
    }
  };

  const validateBeforeNext = () => {
    let payload = _.clone(validationErrors);
    if (_.isEmpty(object?.title)) {
      payload = { ...payload, title: "Title is required" };
    }
    if (_.isEmpty(object.thumbnail?.url)) {
      payload = { ...payload, thumbnail: "Thumbnail is required" };
    }
    if (_.isEmpty(object?.video?.link)) {
      payload = { ...payload, link: "Video link is required" };
    } else {
      let link = object?.video?.link;
      if (!ReactPlayer.canPlay(link)) {
        payload = { ...payload, link: "Video link is invalid" };
      }
    }
    //set State Error
    setState((prev) => ({
      ...prev,
      validationErrors: {
        ...prev.validationErrors,
        ...payload,
      },
    }));
    //next if empty
    if (_.isEmpty(payload)) {
      handleChangeStep();
    }
  };

  //suggesstions
  const handleSuggestion = (type) => {
    const title = VIDEOSUGGESTIONS;
    const size = _.size(VIDEOSUGGESTIONS) - 1;
    let value = null;
    if (type === "next") {
      if (suggestion > size) {
        setSuggestion(1);
        value = title[0];
      } else {
        value = title[suggestion];
        setSuggestion((prev) => prev + 1);
      }
    } else {
      let counter = suggestion - 2;
      if (suggestion === 0 || suggestion === 1) {
        value = title[size];
        setSuggestion(size);
      } else {
        if (counter > 0) {
          value = title[counter];
          setSuggestion((prev) => prev - 1);
        } else if (counter === 0) {
          value = title[counter];
          setSuggestion(size + 2);
        }
      }
    }
    if (value) {
      let payload = _.clone(object);
      let data = update(payload, {
        title: { $set: value },
      });
      //handle set
      setEditorState(convertToEditorState(value));
      setObject(data);
      handleOnSave(data);
    }
  };

  return (
    <div className="w-full">
      {/* header */}
      <div className="w-full">
        <p className="font-medium text-2xl">Add Your Content</p>
      </div>
      {/* end header */}
      <div className="w-full pt-5">
        {/* left card start */}
        <div
          className="bg-white rounded-xl relative campaign-left-max"
          style={{ height: "fit-content" }}
        >
          <div className="p-5">
            {/* first */}
            <div>
              <p className="text-xl pl-1 pb-2">Design your videos</p>
            </div>
            {/* middle */}
            <div className="w-full mb-2">
              <p className="pl-1 pt-2 pb-2">Type your heading here:</p>
              <WYSIWYGEditorDraft
                placeholder={`e.g. Hear from our customers`}
                onChange={(value) => {
                  handleChangeValue(value, "title");
                }}
                onBlur={handleOnBlur}
                errorMessage={validationErrors?.title}
                showCounter={true}
                maxLength={MAX_TITLE_LENGTH}
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </div>
            {/* title_decoration */}
            <div className="w-full flex items-center 5md:block 3lg:flex 3lg:items-center justify-start">
              <SuggestionButton onClick={(e) => handleSuggestion(e)} />
            </div>
            {/* Uploads and others */}
            <div>
              <p className="pl-1 pt-2 pb-2">
                Upload a thumbnail for your video:
              </p>
              {validationErrors.thumbnail && (
                <p className="text-red text-xs mt-2">
                  * {validationErrors.thumbnail}
                </p>
              )}
              <FileUpload
                leftContainerClass="thumbnail-view flex ml-1"
                leftContentClass="w-full rounded-md"
                iconLeft={
                  <AiFillVideoCamera
                    className="text-2xl"
                    style={{ color: "#CDCDCD" }}
                  />
                }
                wrapperClass="mt-3"
                rightContainerClass="px-6 py-1"
                rightContentClass="my-0"
                accept="image/*" // default: null, files to accept
                width={"100%"} // default: 100%, width for right section label
                image_url={object.thumbnail?.url} // default: null, image fetched when uploaded to server
                uploading={image.uploading} // default: false, upload loader
                maxFileSize="300KB"
                maxFileText="Maximum upload file size"
                allowedFiles="SVG, JPG, PNG, GIF"
                onChange={onUploadImage}
                name="template-videos"
                id="template-videos"
                removable={true}
                onRemoveImage={handleRemoveImage}
              />
              {/* others textfield */}

              {/*  range */}
              <p className="pl-1 pt-2 pb-2">Complete your content:</p>
              <div className="relative mx-1 mb-3">
                <InputRange
                  label="Star Rating (Out Of 5)"
                  name="rating"
                  defaultValue={object?.video?.rating}
                  onChange={(e) =>
                    handleChangeVideoLinks(
                      parseFloat(e?.target?.value),
                      "rating"
                    )
                  }
                />
              </div>
              <Input
                inputIcon={<LinkInputIconSVG />}
                iconPosition="left"
                inputContainerClass="input-container-2"
                label="Link A Video From YouTube or Vimeo"
                placeholder={`e.g. https://www.youtube.com/`}
                name="link"
                type="text"
                value={object?.video?.link}
                paddingTop="1.5rem"
                labelClass="input-label-xs"
                onChange={(e) =>
                  handleChangeVideoLinks(e?.target?.value, "link")
                }
                onBlur={handleOnBlur}
                errorMessage={validationErrors?.link}
              />
              <Input
                inputContainerClass="input-container-2"
                placeholder={`e.g. Excepteur sint occaecat cupidatat non proident, sunt in...`}
                paddingTop="1.5rem"
                labelClass="input-label-xs"
                label="Heading"
                name="heading"
                type="text"
                value={object?.video?.heading}
                showCounter={true}
                maxLength={100}
                onChange={(e) =>
                  handleChangeVideoLinks(e?.target?.value, "heading")
                }
                onBlur={handleOnBlur}
                errorMessage={validationErrors?.heading}
              />
              <Input
                inputContainerClass="input-container-2"
                placeholder={`e.g. Lorem ipsum dolor sit consectetur adipiscing elit ut aliquip ex ea commodo consequat.`}
                label="Body Text"
                labelClass="input-label-xs"
                name="body"
                type="text"
                value={object?.video?.body}
                showCounter={true}
                maxLength={100}
                onChange={(e) =>
                  handleChangeVideoLinks(e?.target?.value, "body")
                }
                onBlur={handleOnBlur}
                errorMessage={validationErrors?.body}
              />
            </div>
          </div>
          {/* footer */}
          <div className="border-t border-tabBorder">
            <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
              <Button
                buttonName="PREVIOUS"
                buttonClass="bg-transparent rounded-full py-3"
                buttonTextClass="text-sm font-bold"
                buttonType="transparent"
                onClick={() => setSelectedTab("template")}
              />
              <Button
                buttonName="NEXT"
                buttonClass="relative bg-primary rounded-full py-3 px-12"
                buttonType="primary"
                onClick={validateBeforeNext}
                disabled={loading}
                icon={
                  <div className="absolute right-2 bottom-0 top-0 flex items-center">
                    {loading && (
                      <LoadingIndicator className="text-white w-4 h-4" />
                    )}
                  </div>
                }
              />
            </div>
          </div>
        </div>
        {/* end card start */}
      </div>
    </div>
  );
}
