import _ from "lodash";
import ReactPlayer from "react-player";

export const csx = (classes) => classes.filter(Boolean).join(" ");

const checkPoints = (icons) => {
  if (icons) {
    const obj = _.filter(icons, function (o) {
      return o.title;
    });
    return _.size(obj);
  }
};

const checkStats = (icons) => {
  if (icons) {
    const obj = _.filter(icons, function (o) {
      return o.data;
    });
    return _.size(obj);
  }
};

const checkLogos = (logos) => {
  if (logos) {
    const obj = _.filter(logos, function (o) {
      return o.image_id;
    });
    return _.size(obj);
  }
};

const checkPoint = (content) => {
  const contacts = _.clone(content.contacts);
  const fileds = [
    "address",
    "contact_number",
    "contact_title",
    "email_address",
  ];

  const obj = _.filter(contacts, function (o) {
    let payload = {
      address: false,
      contact_number: false,
      contact_title: false,
      email_address: false,
    };
    _.forEach(fileds, (e) => {
      if (!_.isEmpty(o[e].value) && o[e].visible) {
        payload[e] = true;
      }
    });
    if (
      payload.contact_title ||
      payload.email_address ||
      payload.contact_number ||
      payload.address
    ) {
      return true;
    } else {
      return false;
    }
  });
  return _.size(obj);
};

const validateCalendyLink = (link) => {
  if (link.indexOf("https://calendly.com/") === 0) {
    const url = _.split(link, "/");
    const size = _.size(url);
    if (size >= 5 && size <= 7) {
      if (url[4]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  return false;
};

export const globalValidation = (popup, tab) => {
  let error = [];
  let exempted = ["basic"];
  let exempted2 = ["basic", "popout"];
  if (!exempted.includes(tab)) {
    if (!popup.id || !popup.title || !popup.website_url) {
      error.push(
        "Please enter a Website URL and a title before moving forward with your campaign"
      );
    } else if (_.size(popup.title) > 254) {
      error.push("The title must not be greater than 254 characters.");
    }
  }
  if (!exempted2.includes(tab)) {
    if (popup.quote || popup.image) {
      if (_.size(popup.quote) > 254) {
        error.push("The quote must not be greater than 254 characters.");
      }
    } else if (popup.image === null && popup.quote === null) {
      error.push("Image or quote is empty");
    }
  }

  return error;
};

export const validateTemplates = (data) => {
  const payload = data ? JSON.parse(data) : {};
  let error = [];
  if (payload?.type !== "promotions") {
    if (_.isEmpty(payload?.title)) {
      error.push("Template title is required");
    }
  }
  if (payload?.type === "proof-points") {
    if (checkPoints(payload?.icons) < 2) {
      error.push("At least 2 Proof Points are required.");
    }
  } else if (payload?.type === "statistics-data") {
    if (checkStats(payload?.stats) < 2) {
      error.push("At least 2 Statistics Data are required.");
    }
  } else if (payload?.type === "logos") {
    if (checkLogos(payload?.logos) < 4) {
      error.push("Please upload at least 4 logos.");
    }
  } else if (payload?.type === "promotions") {
    if (_.isEmpty(payload?.thumbnail?.url)) {
      error.push("Thumbnail is required.");
    }
  } else if (payload?.type === "testimonials") {
    let testimonials = payload?.testimonials;
    if (_.isEmpty(testimonials[0]?.quote)) {
      error.push("Testimonial Quote is required");
    }
    if (_.isEmpty(testimonials[0]?.name)) {
      error.push("Testimonial Name is required");
    }
  } else if (payload?.type === "videos") {
    if (_.isEmpty(payload?.thumbnail?.url)) {
      error.push("Template Thumbnail is required");
    }
    if (_.isEmpty(payload?.video?.link)) {
      error.push("Template Video link is required");
    } else {
      let link = payload?.video?.link;

      if (!ReactPlayer.canPlay(link)) {
        error.push("Video link is invalid");
      }
    }
  }
  return error;
};

export const validateContacts = (data) => {
  const content = data ? JSON.parse(data) : {};
  let error = [];

  if (content.type === "calendy-event-scheduler") {
    if (_.isEmpty(content.link)) {
      error.push("Calendly link is required.");
    } else {
      let link = content.link;
      if (!validateCalendyLink(link)) {
        error.push("Input valid calendly link.");
      }
    }
  } else if (content.type === "hubspot-form") {
    if (_.isEmpty(content.portalId)) {
      error.push("Portal ID is required.");
    }
    if (_.isEmpty(content.formId)) {
      error.push("Form ID is required.");
    }
  } else {
    if (_.isEmpty(content?.title)) {
      error.push("Contact title is required");
    }

    if (content.type === "enquiry-form") {
      if (content.show_enquiry_type) {
        if (_.isEmpty(content.enquiry_type_options)) {
          error.push("The Enquiry Type needs at least one(1) option.");
        }
      }
      if (_.isEmpty(content.submit_button.text)) {
        error.push("A Button Name is required.");
      }
    }

    if (content.type === "lead-magnet") {
      if (_.isEmpty(content.submit_button.text)) {
        error.push("A Button Name is required.");
      }
    }

    if (content.type === "multiple-addresses") {
      if (!checkPoint(content)) {
        error.push("At least 1 Contact is required.");
      }
    }
  }
  console.log("error", error);
  return error;
};

export const DisplayErrorMessage = ({ errors }) => {
  return (
    <div className="w-full">
      <ul className="w-full text-sm">
        {errors.map((value, index) => (
          <li key={index} className="flex items-center">
            {value}
          </li>
        ))}
      </ul>
    </div>
  );
};

export const checkValidWebsite = (url = "") => {
  return /\.(jpg|jpeg|png|webp|avif|gif|pdf)$/.test(url);
};
