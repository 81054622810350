import CornerCurvedIconSVG from "assets/svg/corner-curved";
import CornerRoundedIconSVG from "assets/svg/corner-rounded";
import CornerSharpIconSVG from "assets/svg/corner-sharp";
import _ from "lodash";
import moment, * as Moment from "moment";
import { extendMoment } from "moment-range";
import { AiOutlineUnderline } from "react-icons/ai";
import { GoBold, GoItalic } from "react-icons/go";
import sanitizeHtml from "sanitize-html";
import { validateContacts, validateTemplates } from "./utils";

const TO_RADIANS = Math.PI / 180;
let previewUrl = "";

export const MAX_TITLE_LENGTH = 250;

export const TEMPLATEOBJ = [
  {
    type: "proof-points",
    title: "",
    title_decoration: "normal",
    icons: [
      {
        title: "",
        subtext: "",
        icon: null,
        visible: true,
      },
      {
        title: "",
        subtext: "",
        icon: null,
        visible: true,
      },
      {
        title: "",
        subtext: "",
        icon: null,
        visible: true,
      },
      {
        title: "",
        subtext: "",
        icon: null,
        visible: true,
      },
    ],
  },
  {
    type: "testimonials",
    title: "",
    title_decoration: "normal",
    testimonials: [
      {
        quote: "",
        quote_decoration: "normal",
        name: "",
        position: "",
        image_url: "",
        image_key: null,
        image_id: null,
        rating: 0,
      },
      {
        quote: "",
        quote_decoration: "normal",
        name: "",
        position: "",
        image_url: "",
        image_key: null,
        image_id: null,
        rating: 0,
      },
    ],
  },
  {
    type: "videos",
    title: "",
    title_decoration: "normal",
    video: {
      link: "",
      heading: "",
      body: "",
      rating: 0,
    },
    thumbnail: {
      url: "",
      media_id: "",
    },
  },
  {
    type: "promotions",
    title: "",
    title_decoration: "normal",
    show_ticks: true,
    points: [
      {
        text: "",
        visible: true,
      },
      {
        text: "",
        visible: true,
      },
      {
        text: "",
        visible: true,
      },
      {
        text: "",
        visible: true,
      },
    ],
    thumbnail: {
      url: "",
      media_id: "",
    },
  },
  {
    type: "statistics-data",
    title: "",
    title_decoration: "normal",
    stats: [
      {
        data: "",
        subtext: "",
        icon: null,
        visible: true,
      },
      {
        data: "",
        subtext: "",
        icon: null,
        visible: true,
      },
      {
        data: "",
        subtext: "",
        icon: null,
        visible: true,
      },
      {
        data: "",
        subtext: "",
        icon: null,
        visible: true,
      },
    ],
  },
  {
    type: "coupons",
    title: "",
    title_decoration: "normal",
    coupons: [
      {
        enabled: true,
        title: "",
        subtitle: "",
        description: "",
        expiryText: "",
        code: "",
        termsText: "",
        termsLink: "",
      },
      {
        enabled: false,
        title: "",
        subtitle: "",
        description: "",
        expiryText: "",
        code: "",
        termsText: "",
        termsLink: "",
      },
      {
        enabled: false,
        title: "",
        subtitle: "",
        description: "",
        expiryText: "",
        code: "",
        termsText: "",
        termsLink: "",
      },
      {
        enabled: false,
        title: "",
        subtitle: "",
        description: "",
        expiryText: "",
        code: "",
        termsText: "",
        termsLink: "",
      },
    ],
    testimonalRating: 5,
    testimonialContent: "",
    testimonialAuthor: "Jane Doe - Sample Business",
  },
];

export const CONTACTOBJ = [
  {
    type: "standard-details",
    title: "",
    title_decoration: null,
    contact_number: {
      value: "",
      visible: true,
    },
    email_address: {
      value: "",
      visible: true,
    },
    address: {
      value: "",
      pin: "",
      visible: true,
      latitude: 0,
      longitude: 0,
    },
    show_ticks: true,
    image_id: null,
    image_url: null,
    image_key: null,
    video_link: null,
    points: [
      {
        point: "",
        visible: true,
      },
      {
        point: "",
        visible: true,
      },
      {
        point: "",
        visible: true,
      },
      {
        point: "",
        visible: true,
      },
    ],
    buttons: [
      {
        text: "",
        url: "",
        visible: true,
      },
      {
        text: "",
        url: "",
        visible: true,
      },
      {
        text: "",
        url: "",
        visible: true,
      },
    ],
  },
  {
    type: "enquiry-form",
    title: "",
    title_decoration: null,
    submit_text: "",
    submit_email_address: "",
    show_name: true,
    show_email_address: true,
    show_phone: true,
    show_enquiry_type: true,
    enquiry_type_options: [],
    show_message: true,
    show_ticks: true,
    image_id: null,
    image_url: null,
    image_key: null,
    video_link: null,
    contact_number: {
      value: "",
      visible: true,
    },
    email_address: {
      value: "",
      visible: true,
    },
    address: {
      value: "",
      pin: "",
      visible: true,
      latitude: 0,
      longitude: 0,
    },
    text: {
      value: "",
      visible: true,
    },
    submit_button: {
      text: "",
      email: "",
      visible: true,
    },
  },
  {
    type: "lead-magnet",
    title: "",
    title_decoration: null,
    submit_email_address: "",
    show_name: true,
    show_email_address: true,
    show_phone: false,
    show_enquiry_type: false,
    enquiry_type_options: [],
    show_message: false,
    show_ticks: true,
    image_id: null,
    image_url: null,
    image_key: null,
    video_link: null,
    contact_number: {
      value: "",
      visible: true,
    },
    email_address: {
      value: "",
      visible: true,
    },
    address: {
      value: "",
      pin: "",
      visible: true,
      latitude: 0,
      longitude: 0,
    },
    text: {
      value: "",
      visible: true,
    },
    submit_button: {
      text: "",
      email: "",
      visible: true,
    },
  },
  {
    type: "map-assistance",
    title: "",
    title_decoration: null,
    show_ticks: true,
    text: {
      value: "",
      visible: true,
    },
    contact_number: {
      value: "",
      visible: true,
    },
    email_address: {
      value: "",
      visible: true,
    },
    address: {
      value: "",
      pin: "",
      zoom: 10,
      latitude: 0,
      longitude: 0,
    },
    buttons: [
      {
        text: "",
        url: "",
        visible: true,
      },
      {
        text: "",
        url: "",
        visible: true,
      },
      {
        text: "",
        url: "",
        visible: true,
      },
    ],
  },
  {
    type: "multiple-addresses",
    title: "",
    title_decoration: null,
    contacts: [
      {
        contact_title: {
          value: "",
          visible: true,
        },
        contact_number: {
          value: "",
          visible: true,
        },
        email_address: {
          value: "",
          visible: true,
        },
        address: {
          value: "",
          pin: "",
          visible: true,
          latitude: 0,
          longitude: 0,
        },
      },
      {
        contact_title: {
          value: "",
          visible: true,
        },
        contact_number: {
          value: "",
          visible: true,
        },
        email_address: {
          value: "",
          visible: true,
        },
        address: {
          value: "",
          pin: "",
          visible: true,
          latitude: 0,
          longitude: 0,
        },
      },
      {
        contact_title: {
          value: "",
          visible: true,
        },
        contact_number: {
          value: "",
          visible: true,
        },
        email_address: {
          value: "",
          visible: true,
        },
        address: {
          value: "",
          pin: "",
          visible: true,
          latitude: 0,
          longitude: 0,
        },
      },
      {
        contact_title: {
          value: "",
          visible: true,
        },
        contact_number: {
          value: "",
          visible: true,
        },
        email_address: {
          value: "",
          visible: true,
        },
        address: {
          value: "",
          pin: "",
          visible: true,
          latitude: 0,
          longitude: 0,
        },
      },
    ],
  },
  {
    type: "calendy-event-scheduler",
    title: "",
    title_decoration: null,
    link: "",
  },
  {
    type: "hubspot-form",
    title: "",
    title_decoration: null,
    embed: "",
    portalId: "",
    formId: "",
    region: "",
  },
];

export const POPUP_INITIALS = {
  campaign: {},
  campaign_id: null,
  website_url: "",
  owner_id: null,
  parent_id: null,
  industry_id: null,
  media_id: null,
  font_id: null,
  desktop_image_id: null,
  mobile_image_id: null,
  primary_colour: "#FFFFFF",
  button_colour: "#000000",
  button_corner_style: "rounded",
  tab_font_colour: "#000000",
  button_font_colour: "#FFFFFF",
  status: "active",
  goal: null,
  icon_position: "above",
  corner_style: "sharp",
  dismiss_tutorial: 0,
  deleted_at: null,
  created_at: null,
  updated_at: null,
  template_type: "proof-points",
  template_content: JSON.stringify(TEMPLATEOBJ[0]), //object
  contact_method_type: "standard-details",
  contact_method_content: JSON.stringify(CONTACTOBJ[0]), //object
  parent: null,
  industry: null,
  tab_image_size: 0,
  container_width: 120,
  font: null,
  icon: null,
};

//['sharp', 'rounded', 'curved']
export const CORNER_STYLES = [
  { type: "sharp", icon: CornerSharpIconSVG },
  { type: "curved", icon: CornerCurvedIconSVG },
  { type: "rounded", icon: CornerRoundedIconSVG },
];

export const QUOTESUGGESTIONS = [
  "Need A Hand?",
  "Looking For Something Specific?",
  "Need More Information?",
  "Speak To One Of Our Specialists",
  "Get A Call Back Today",
  "Still Need A Hand?",
  "Everything You Need To Know",
  "Quick Website Summary",
  "Get A Quick Quote",
  "Quick Emergency Contact",
  "Get Help From An Expert",
  "Start Your Project Today",
  "Join The Exclusive VIP List",
  "Request A Demo",
  "Lets Get Started",
  "Request A Quote",
  "Contact An Expert",
  "Call Us Today",
  "Speak Directly To Our Team",
  "Looking For Something?",
  "Can't Find What You're Looking For",
  "Lets Chat",
  "Connect With Us",
  "All You Need To Know",
  "Book A Demo",
  "Quick Reference",
  "Contact Us Today",
  "Make An Enquiry",
  "Get A Free Sample",
  "Try It For Free",
  "Get 10% Off Today",
  "Sign Up Here",
  "Quick Start",
  "Chat With Our Team Today",
  "Would You Like Some Help?",
  "Enquire Now",
  "Why Choose Us",
  "Get A Quick Hand",
  "Not sure where to start?",
  "Got A Curious Question?",
  "Keen To Get Started?",
  "Start Getting Results",
];

export const VIDEOSUGGESTIONS = [
  "See How Easy It Is to Install With Our 2 Minute Demo",
  "Tired Of Reading? Watch Our Teaser Trailer Instead.",
  "Explore The Grounds In Our Virtual Tour",
  "Dive Into The Experience With This Powerful Storytelling",
  "Click To Play And Immerse Yourself In Our Showreel",
  "Let Us Give You A Taste of Our &lt;Product&gt; To You In This Quick & Easy Recipe",
  "Watch Our How-to Video To See How It Works",
  "Meet Our Resident Expert &lt;Name&gt;, For a Quick Chat On &lt;Service&gt;",
  "Watch Our Highlight Reel of Past Projects To See What We Could Do For You",
  "Enjoy A Quick Taster Of Who We Are & What We’re All About",
  "Follow Our Straightforward DIYs to Set Up Your Own &lt;Object&gt; In Under 5 Minutes",
  "How We Helped Our Customers With &lt;Service&gt; & How Their Lives Changed Immediately",
];

export const LOGOSUGGESTIONS = [
  "A Team of Top-tier Suppliers & Partners To Deliver You The Best",
  "A Fleet of The Best In Town To Take You To Your Destination",
  "Our Growing Network Of Associates & Dealers",
  "Trusted By 100s Of Local &lt;Sector&gt; Businesses",
  "A Family Of Creators Who Enjoy Teaching, Sharing & Inspiring",
  "Brands We’re Privileged To Work With",
  "A Passionate Community Of Suppliers From Around The World",
  "Featured Clients & Amazing Partners",
  "A Bundle Of Choices So You Can Tailor Your Project To A Tee",
  "We’ve Got A Buffet Of Choices, Take Your Pick From The Leading Brands",
  "Working With More Than X+ Innovative Companies To Offer You Unbeatable Solutions",
  "Our Hub of Specialists Are Ready To Assist You Today",
  "Strategic Partnerships For A Superb Range Of Choice",
];

export const PROMOTIONSUGGESTIONS = [
  "Score On Unmissable Value In This Limited Time Offer",
  "Start Saving &lt;Amount&gt; Right Away When You Sign Up Today",
  "Reasons Why You’ll Absolutely Love Us",
  "Save 30% On &lt;Example&gt; Fees If You Book A Demo With Us Before June",
  "Free Delivery To Your Door When You Order Before 11am",
  "Receive 3 Complimentary Gifts With Every Booking In Our &lt;Venue&gt;",
  "Try The Best &lt;Example&gt; Service Risk-free With A 2 Week Free Trial",
];

export const TESTIMONIALSUGGESTIONS = [
  "What Our Customers Think Of Our Service",
  "How We’ve Helped Thousands Of Others Just Like You",
  "Here’s What Our Beloved Clients Have To Say",
  "How We’ve Changed Their Lives. Will You Be Next?",
  "Are You Ready For The Next Phase Of Growth?",
  "Straight From The Horses’ Mouth",
  "How Our Past Clients Have Enjoyed The Experience",
  "Some Of Our Customers’ Favourite Moments & Memories",
  "How We’ve Helped Others On Their Important Journey",
  "Some Of The Clubs We’ve Been Lucky To Work With",
  "We Build Rock Solid Relationships, In & Out Just Isn’t Our Style",
];

export const PROFFPOINTSUGGESTIONS = [
  "The Best Of Our Latest Results",
  "They Say Proof’s In The Pudding",
  "Figures That Speak For Themselves",
  "A Few Noteworthy Numbers To Highlight",
  "Accelerate & Scale Faster With Our Full Support",
  "Professional &lt;Service&gt; With A Human Touch",
  "Everything You Need to Make Your Moment Special",
  "Professionalism At All Stages Of The &lt;Industry&gt; Service",
  "Designed To Kick Off Exponential Growth",
  "The Points Of Difference In Our Offering",
  "A Level Of Service You Won’t Get Anywhere Else",
  "Reasons You Should Jump On Board With Us",
  "Why There’s No One Else Better Equipped To Lend You A Hand",
  "It’s The Little Details Done Well That Make the Big Difference",
  "How We Streamline The Process For Our Clients",
  "Don’t Settle When You Can Have It All",
  "The Leading &lt;Service&gt; Provider Of The Year",
  "We Help &lt;Target Market&gt; Get &lt;Results&gt; By &lt;Solution&gt;",
  "We Help &lt;Target Market&gt; Get &lt;Results&gt; For Over &lt;Years&gt;",
];

export const TITLESUGGESTIONS = [
  "Shave Time. Shave Money.",
  "There are some things money can't buy. For everything else, there's MasterCard.",
  "Melts in Your Mouth, Not in Your Hands",
  "A Diamond Is Forever",
  "Tastes So Good, Cats Ask for It By Name",
  "5G Built Right.",
  "We Help the World Grow the Food It Needs",
  "Because You're Worth It.",
  "Just Do It.",
  "All the News That's Fit to Print",
];

export const NEWSLETTERSUGGESTIONS = [
  "Become A Part Of Our Exclusive List",
  "Subscribe Today For Insights Dedicated to Helping You",
  "Sign Up For The Perks, Stay To Be Inspired",
  "Can’t Get Enough Of Us? Subscribe For Lots More",
  "Follow For The Latest Leadership News & Brainstorms",
  "Follow Us For The Latest Tips From Our Academy",
  "Become A Member Today And Receive A Little Gift In Your Inbox",
  "Discover The Hidden Secrets Of &lt;Industry&gt; & Get Ahead",
  "Sign Up To Keep Posted On What’s Happening In Our World",
  "Interested In Easy To Follow How-to Videos & Articles?",
  "Upskill Yourself With Professional Direction & Experience",
  "Get Valuable Lessons Delivered Straight Into Your Inbox",
  "Subscribe For Weekly Inspiration & Insights That Will Freshen Up Your Inbox",
  "Join The Tribe & Empower Yourself",
  "Welcome To The Revolution, Let’s Discover Yourself",
  "Subscribe To Valuable Insights & The Latest News",
  "You Don’t Have To Do It Alone, Let Us Help You",
  "Straightforward Tutorials & Hands-On Advice In Your Inbox",
  "Join Our Mailing List For Hand-picked Inspiration",
  "Sign Up To Receive All Our Great Deals",
  "Welcome To The Club Dear Friend, Come On In",
  "Don’t Miss Out & Save A Spot Today",
  "Are You Ready To Be Completely Immersed In &lt;Industry&gt;",
];

export const ENQUIRYFORMSUGGESTIONS = [
  "Speak Directly To A Specialist",
  "Connect With Our Team",
  "Let’s Chat Over A Virtual Cuppa",
  "It All Starts With Having A Simple Conversation",
  "Request A Quote Today",
  "Tell Us What You’re After, For A Quick & Accurate Quote",
  "Start Planning Tomorrow, Today",
  "Begin Your Unforgettable Journey With Us",
  "Real Help Is Closer Than You Think, Reach Out Today",
  "Start Planning Your Adventure With A Trusted Guide",
  "Forget Your Chores, Start Planning Your Holiday Today",
  "Less Thinking, More Doing. Start Today.",
  "Book In A Personalised Demo",
  "Have A Chat With Us And Let Us Show You How It All Works",
  "Request A Quick Quote Now & Get It Today",
  "Let’s Dive Into Your Story, We’re Here To Understand",
  "Let’s Take A Step Into Building Your Next Chapter",
  "Book In A Chat With One Of Our Advisors",
  "Book In A Time With A Specialist & Get Help Today",
  "Secure Your Date Today To Save Your Dream Day",
  "Let’s Take Your Next Step Together",
  "Unsure Where To Start, Let’s Begin With A Quick Chat",
  "Need Help? Let’s Point You In The Right Direction",
  "Let Us Guide Your Next Step And Give You 100% Assurance",
  "Let’s Start The Conversation That Matters",
  "We’re Here To Understand You, We’re Here To Help",
  "Streamline Your Operations Today",
  "Are You Ready To Get Started?",
  "Stop Waiting And Seek Professional Assistance Today",
  "Let Our Account Managers Assist You",
  "Our Team of Experts Are Ready When You Are",
  "Let’s Unpack Your Project Together",
  "Why You Should Pick &lt;Company Name&gt;",
  "Have A Project In Mind? Let’s Talk.",
  "We Love A New Challenge, Share Yours With Us",
  "We’ll Go The Extra Mile To Deliver In Timely Fashion",
  "Like What You See? Let’s Work Together",
  "Don’t Be Afraid To Say Hello, We Don’t Bite",
];

export const SCREENSHOTSOBJ = (e) => {
  const obj = {
    image_urls: [
      {
        device: "desktop",
        url: e.desktop_image?.url,
      },
      {
        device: "mobile",
        url: e.mobile_image?.url,
      },
    ],
    media_ids: [
      {
        device: "desktop",
        id: e.desktop_image?.id,
      },
      {
        device: "mobile",
        id: e.mobile_image?.id,
      },
    ],
  };
  return obj;
};

export const btnCorner = (corner) => {
  let result = 0;
  if (corner === "rounded") {
    result = "999px";
  } else if (corner === "curved") {
    result = "8px";
  }
  return result;
};

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const SPLIT_SETTINGS_OBJ = {
  type: "",
  value: "",
};

export const toBlob = (canvas) => {
  return new Promise((resolve) => {
    canvas.toBlob(resolve);
  });
};

export async function canvasToFile(
  image,
  crop,
  scale = 1,
  rotate = 0,
  name = "crop-image"
) {
  const canvas = document.createElement("canvas");
  canvasPreview(image, canvas, crop, scale, rotate);

  const blob = await toBlob(canvas);

  if (!blob) {
    console.error("Failed to create blob");

    return "";
  }

  if (previewUrl) {
    URL.revokeObjectURL(previewUrl);
  }

  previewUrl = URL.createObjectURL(blob);
  let file = new File([blob], name);
  return { image: file, previewUrl: previewUrl };
}

export async function canvasPreview(
  image,
  canvas,
  crop,
  scale = 1,
  rotate = 0
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

const formatLabel = (start, end) => {
  let currentStartMonth = moment(start).format("MMM");
  let currentEndMonth = moment(end).format("MMM");
  let year = moment(end).format("YYYY");
  if (currentStartMonth === currentEndMonth) {
    return `${moment(start).format("MMM DD")} - ${moment(end).format(
      "DD"
    )}, ${year}`;
  } else {
    return `${moment(start).format("MMM DD, YYYY")} - ${moment(end).format(
      "MMM DD, YYYY"
    )}`;
  }
};

export const getWeeks = (startDate) => {
  const nlrStart = moment(startDate).utc().startOf("isoWeek");
  const nlrEnd = moment().utc().endOf("isoWeek");

  let options = [];
  let s = nlrStart.unix();
  let e = nlrEnd.unix();
  let weeksNew = [];

  while (s < e) {
    weeksNew.push({
      start: moment.unix(s).utc().startOf("isoWeek"),
      end: moment.unix(s).utc().endOf("isoWeek"),
    });
    s = s + 7 * 24 * 60 * 60 + 60;
  }
  weeksNew = weeksNew.reverse();

  if (!_.isEmpty(weeksNew)) {
    weeksNew.forEach((it, index) => {
      options.push({
        label: formatLabel(it.start, it.end),
        value: `week-${index}-${it.start.format("YYYY")}`,
        dates: {
          start: moment(it.start).unix(),
          end: moment(it.end).unix(),
        },
      });
    });
  }

  return options;
};

export const getMonths = (startDate) => {
  const nlrStart = moment(startDate).utc().startOf("month");
  const nlrEnd = moment().utc().endOf("month");

  let options = [];
  let s = nlrStart.unix();
  let e = nlrEnd.unix();
  let monthsNew = [];

  while (s < e) {
    monthsNew.push({
      start: moment.unix(s).utc().startOf("month"),
      end: moment.unix(s).utc().endOf("month"),
    });
    s = moment.unix(s).utc().add(1, "month").unix();
  }

  monthsNew = monthsNew.reverse();

  if (!_.isEmpty(monthsNew)) {
    monthsNew.forEach((it, index) => {
      options.push({
        label: it.start.format("MMMM YYYY"),
        value: `month-${index}-${it.start.format("YYYY")}`,
        dates: {
          start: moment(it.start).unix(),
          end: moment(it.end).unix(),
        },
      });
    });
  }

  return options;
};

export const getDays = (start, end) => {
  const moment = extendMoment(Moment);

  //Get the first and last day of the month
  let firstDay = moment(start);
  //current date
  let endDay = moment(end);
  //Create a range for the month we can iterate through
  let monthRange = moment.range(firstDay, endDay);

  //Get all the weeks during the current month
  let daysLabel = [];

  const days = Array.from(monthRange.by("day"));

  days.forEach((it) => {
    let formatDate = moment(it).format("MM/DD");
    daysLabel.push(formatDate);
  });

  return daysLabel;
};

export const positions = [
  "right center",
  "right center",
  "right bottom",
  "right bottom",
];

export const selectText = () => {
  let selectedText = "";

  if (window.getSelection) {
    selectedText = window.getSelection();
  } else if (document.getSelection) {
    selectedText = document.getSelection();
  } else if (document.selection) {
    selectedText = document.selection.createRange().text;
  } else return;

  return selectedText.toString();
};

export const formatTitle = (type, value) => {
  //bold italic underline
  if (type === "bold") {
    return `<b>${value}</b>`;
  } else if (type === "italic") {
    return `<i>${value}</i>`;
  } else if (type === "underline") {
    return `<u>${value}</u>`;
  }
};

export const isValidHttpUrl = (str) => {
  const url = str.replace("www.", "");
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(url);
};

export const TEXT_DECORATION_CONTACT = [
  { type: "bold", icon: <GoBold />, key: "fontWeight" },
  { type: "italic", icon: <GoItalic />, key: "fontStyle" },
  { type: "underline", icon: <AiOutlineUnderline />, key: "textDecoration" },
];

export const popoutValidationAll = (popup) => {
  let error = [];
  if (_.size(popup.title) > 254) {
    error.push("The title must not be greater than 254 characters.");
    return { error: error, menu: "basic" };
  }

  if (popup.template_type) {
    const result = validateTemplates(popup.template_content);
    if (!_.isEmpty(result)) {
      error = [...error, ...result];
      return { error: error, menu: "content" };
    }
  }

  if (popup.contact_method_type) {
    const resultContacts = validateContacts(popup.contact_method_content);
    if (!_.isEmpty(resultContacts)) {
      error = [...error, ...resultContacts];
      return { error: error, menu: "contactD" };
    }
  }
};

export const sanitizeValueHTML = (dirty) => {
  return sanitizeHtml(dirty, {
    allowedTags: ["b", "i", "em", "strong", "ins", "u", "p"],
  });
};
