import { Fragment } from "react";
import styled from "styled-components";
import { sanitizeValueHTML } from "utils/constant";
import HeaderTemplate from "../../commons/header-template";
import CouponBox from "./coupon-box";
import CouponTestimonialCard from "./testimonial-card";

const CouponsContainer = styled.div`
  margin-top: 36px !important;
`;

export default function Coupons({ data }) {
  return (
    <Fragment>
      <div style={{ width: "100%", height: "100%" }}>
        <HeaderTemplate
          family={data.font?.family}
          width={data.width}
          dangerouslySetInnerHTML={{ __html: sanitizeValueHTML(data.title) }}
        />
        <CouponsContainer responsive={data.width}>
          {data.coupons
            .filter((x) => x.enabled)
            .map((coupon, idx) => (
              <CouponBox
                key={idx}
                coupon={coupon}
                width={data.width}
                color={data.element_colour}
                buttonTextColor={data.element_text_color}
                cornerStyle={data.button_corner_style}
              />
            ))}
        </CouponsContainer>
        <CouponTestimonialCard
          rating={data.testimonialRating}
          content={data.testimonialContent}
          author={data.testimonialAuthor}
        />
      </div>
    </Fragment>
  );
}
