import { getIcon } from "assets/icons/icons-list";
import LoadingIndicator from "assets/svg/loading-indicator";
import Button from "components/button/button";
import ContentIconSelector from "components/campaigns/icons/content-icon-selector";
import CampaignPopupProofPoints from "components/campaigns/popup/campaign-popup-proofpoints";
import InputDoubleField from "components/input/input-double";
import WYSIWYGEditorDraft, {
  convertToEditorState,
} from "components/wysiwyg/wysiwyg-editor";
import { Context } from "context/context";
import update from "immutability-helper";
import _ from "lodash";
import { useContext, useMemo, useState } from "react";
import {
  MAX_TITLE_LENGTH,
  PROFFPOINTSUGGESTIONS,
  TEMPLATEOBJ,
  positions,
  sanitizeValueHTML,
} from "utils/constant";
import SuggestionButton from "./component/suggestions-btn";

export default function ProofPoints({
  handleChangeStep = () => console.log("We are missing a function here"),
  loading = false,
  handleOnSave = () => {},
  validationErrors = null,
  setState,
}) {
  const { selectedTab, setSelectedTab, popup, setPopup } = useContext(Context); // context
  const initials = useMemo(() => {
    if (_.isEmpty(popup?.template_content)) {
      return _.find(TEMPLATEOBJ, { type: "proof-points" });
    } else {
      return JSON.parse(popup?.template_content);
    }
  }, [popup.template_content]);
  // start wysiwyg
  const titleInitials = useMemo(() => {
    let data = {};
    if (_.isEmpty(popup?.template_content)) {
      data = _.find(TEMPLATEOBJ, { type: "proof-points" });
    } else {
      data = JSON.parse(popup?.template_content);
    }
    return convertToEditorState(data.title || "");
  }, [popup.template_content]);
  const [editorState, setEditorState] = useState(titleInitials);
  // end wysiwyg

  const [object, setObject] = useState(initials);
  const [suggestion, setSuggestion] = useState(0);

  const handleChangeValue = (value, element) => {
    let payload = _.clone(object);
    let finalValue = value;
    let data = payload;
    if (element === "title") {
      if (_.isEmpty(_.trim(value.replace(/(<([^>]+)>)/gi, "")))) {
        finalValue = "";
        data = update(payload, {
          [element]: { $set: finalValue },
          title_decoration: { $set: "normal" },
        });
      } else {
        data = update(payload, {
          [element]: { $set: sanitizeValueHTML(finalValue) },
          title_decoration: { $set: "normal" },
        });
      }
    } else {
      data = update(payload, {
        [element]: { $set: finalValue },
      });
    }
    setObject(data);
    //set to popout
    if (element === "title") {
      setPopup((prev) => ({
        ...prev,
        template_content: JSON.stringify(data),
      }));
    }
  };

  const handleSelectIcon = (icon, index) => {
    let payload = _.clone(object);
    delete payload.icons[index].icon_url;
    delete payload.icons[index].icon_id;
    let data = update(payload, {
      icons: {
        [index]: {
          icon: { $set: icon },
        },
      },
    });
    setObject(data);
    handleOnSave(data);
  };

  const handleChangeText = (value, index, element) => {
    let payload = _.clone(object);
    let data = update(payload, {
      icons: {
        [index]: {
          [element]: { $set: value },
        },
      },
    });
    setObject(data);

    if (element === "visible") handleOnSave(data);
  };

  const handleOnBlur = (e) => handleOnSave(object);

  const checkPoints = () => {
    const icons = _.clone(object.icons);
    const obj = _.filter(icons, function (o) {
      return o.title && o.visible;
    });
    return _.size(obj);
  };

  const checkIconsVisible = () => {
    let icons = object.icons;
    let count = _.size(
      _.filter(icons, function (o) {
        return o.visible;
      })
    );
    return count;
  };

  const validateBeforeNext = () => {
    let payload = _.clone(validationErrors);
    if (_.isEmpty(object?.title)) {
      payload.title = "Title is required";
    }
    if (checkIconsVisible() < 2) {
      payload.icons = "Cannot hide more than 2 Proof Points.";
      payload.iconsError = [true, true];
    }
    if (checkPoints() < 2) {
      payload.icons = "At least 2 Proof Points are required.";
      payload.iconsError = [true, true];
    }
    setState((prev) => ({
      ...prev,
      validationErrors: {
        ...prev.validationErrors,
        ...payload,
      },
    }));

    if (_.isEmpty(payload)) {
      handleChangeStep();
    }
  };

  //suggesstions
  const handleSuggestion = (type) => {
    const title = PROFFPOINTSUGGESTIONS;
    const size = _.size(PROFFPOINTSUGGESTIONS) - 1;
    let value = null;
    if (type === "next") {
      if (suggestion > size) {
        setSuggestion(1);
        value = title[0];
      } else {
        value = title[suggestion];
        setSuggestion((prev) => prev + 1);
      }
    } else {
      let counter = suggestion - 2;
      if (suggestion === 0 || suggestion === 1) {
        value = title[size];
        setSuggestion(size);
      } else {
        if (counter > 0) {
          value = title[counter];
          setSuggestion((prev) => prev - 1);
        } else if (counter === 0) {
          value = title[counter];
          setSuggestion(size + 2);
        }
      }
    }
    if (value) {
      let payload = _.clone(object);
      let data = update(payload, {
        title: { $set: value },
      });
      //handle set
      setEditorState(convertToEditorState(value));
      setObject(data);
      handleOnSave(data);
    }
  };

  return (
    <div className="w-full">
      {/* header */}
      <div className="w-full">
        <p className="font-medium text-2xl">Add Your Content</p>
      </div>
      <div className="w-full pt-5">
        {/* left card start */}
        <div
          className="bg-white rounded-xl relative campaign-left-max"
          style={{ height: "fit-content" }}
        >
          <div className="p-5">
            {/* first */}
            <div className="flex pl-1 pb-2">
              <p className="text-xl mt-1">Design your proof points</p>
              {selectedTab === "content" && (
                <CampaignPopupProofPoints
                  type="proof-points-editor"
                  margin="-60px 0px 0px 0px"
                />
              )}
            </div>
            {/* middle */}
            <div className="mb-2">
              <p className="pl-1 pt-2 pb-2">Type your heading here:</p>
              <WYSIWYGEditorDraft
                placeholder={`e.g. Excepteur sint occaecat cupidatat non proident, sunt in cul...`}
                onChange={(value) => {
                  handleChangeValue(value, "title");
                }}
                onBlur={handleOnBlur}
                errorMessage={validationErrors?.title}
                showCounter={true}
                maxLength={MAX_TITLE_LENGTH}
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </div>

            {/* title_decoration */}
            <div className="w-full flex items-center 5md:block 3lg:flex 3lg:items-center justify-start">
              <SuggestionButton onClick={(e) => handleSuggestion(e)} />
            </div>

            {/* icons */}
            <p className="pl-1 pt-4 pb-2">
              Complete at least 2 proof points here:
            </p>
            <div className="mt-3">
              {validationErrors?.icons && (
                <p className="text-red text-xs mb-3">
                  * {validationErrors?.icons}
                </p>
              )}
              {(object.icons || []).map((value, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-row justify-center space-x-6 pb-5"
                  >
                    <ContentIconSelector
                      onClick={(e) => handleSelectIcon(e, index)}
                      position={positions[index]}
                      Icon={getIcon(value?.icon?.id)}
                    />
                    <InputDoubleField
                      valueUpper={value?.title || ""}
                      valueLower={value?.subtext || ""}
                      error={
                        value?.title
                          ? false
                          : validationErrors?.iconsError?.[index]
                      }
                      visible={value?.visible}
                      onChangeUpper={(e) =>
                        handleChangeText(e?.target?.value, index, "title")
                      }
                      onBlurInputUpper={handleOnBlur}
                      onChangeLower={(e) =>
                        handleChangeText(e?.target?.value, index, "subtext")
                      }
                      maxLength={[65, 120]}
                      onBlurInputLower={handleOnBlur}
                      onChangeVisible={() => {
                        handleChangeText(!value.visible, index, "visible");
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {/* footer */}
          <div className="border-t border-tabBorder">
            <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
              <Button
                buttonName="PREVIOUS"
                buttonClass="bg-transparent rounded-full py-3"
                buttonType="transparent"
                buttonTextClass="text-sm font-bold"
                onClick={() => setSelectedTab("template")}
              />
              <Button
                buttonName="NEXT"
                buttonClass="relative bg-primary rounded-full py-3 px-12"
                buttonType="primary"
                onClick={validateBeforeNext}
                disabled={loading}
                icon={
                  loading && (
                    <div className="absolute right-2 bottom-0 top-0 flex items-center">
                      <LoadingIndicator className="text-white w-4 h-4" />
                    </div>
                  )
                }
              />
            </div>
          </div>
          {/*  */}
        </div>
      </div>
    </div>
  );
}
