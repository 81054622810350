import styled from "styled-components";
import CouponButton from "./coupon-button";

const CouponContainer = styled.div`
  border: 2px dashed ${({ color }) => color};
  border-radius: ${({ cornerStyle }) =>
    cornerStyle === "rounded"
      ? "1rem"
      : cornerStyle === "curved"
      ? "0.5rem"
      : "0rem"};

  width: 100%;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1rem;
  padding: 1.5rem 1rem;
`;

const CouponTitleContainer = styled.div``;

const CouponTitle = styled.div`
  font-size: 2rem;
  line-height: 1.125;
  font-weight: bold;
  color: ${({ color }) => color};
`;

const CouponSubtitle = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 0.125rem;

  @media ${({ theme }) => theme.breakpointLargeDesktop} {
    margin-top: 0.75rem;
  }
`;

const CouponDescriptionContainer = styled.div`
  min-width: 25%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  font-size: 0.925rem;
`;

const CouponTermsLink = styled.a`
  text-decoration: underline;
  color: ${({ color }) => color};
  pointer-events: none;
`;

const CouponTermsText = styled.div`
  color: ${({ color }) => color};
  font-size: 0.8rem;
`;

const CouponCodeContainer = styled.div`
  min-width: 180px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`;

const CouponExpiryText = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 0.925rem;
`;

export default function CouponBox({
  coupon,
  color,
  buttonTextColor,
  cornerStyle,
}) {
  const hasDescription =
    coupon.description && coupon.description.trim() !== "<p></p>";

  return (
    <CouponContainer color={color} cornerStyle={cornerStyle}>
      <CouponTitleContainer>
        <CouponTitle color={color}>{coupon.title}</CouponTitle>
        {coupon.subtitle && <CouponSubtitle>{coupon.subtitle}</CouponSubtitle>}
      </CouponTitleContainer>
      {(hasDescription || coupon.termsText) && (
        <CouponDescriptionContainer>
          {hasDescription && (
            <div
              dangerouslySetInnerHTML={{
                __html: coupon.description,
              }}
            />
          )}
          {coupon.termsText && (
            <CouponTermsText color={color}>
              {coupon.termsLink ? (
                <CouponTermsLink color={color} href={coupon.termsLink}>
                  {coupon.termsText}
                </CouponTermsLink>
              ) : (
                coupon.termsText
              )}
            </CouponTermsText>
          )}
        </CouponDescriptionContainer>
      )}
      {(coupon.code || coupon.expiryText) && (
        <CouponCodeContainer>
          {coupon.code && (
            <CouponButton
              code={coupon.code}
              color={color}
              buttonTextColor={buttonTextColor}
              cornerStyle={cornerStyle}
            />
          )}
          {coupon.expiryText && (
            <CouponExpiryText>{coupon.expiryText}</CouponExpiryText>
          )}
        </CouponCodeContainer>
      )}
    </CouponContainer>
  );
}
