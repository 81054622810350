import PowerByIconSVG from "assets/svg/powered-by-icon";
import { Context } from "context/context";
import { useContext } from "react";

export default function HubspotForm({ popup }) {
  const { view } = useContext(Context);
  const mobile = view === "mobile";

  return (
    <div
      className={
        mobile
          ? "overflow-y-auto mobile-scroll"
          : "contacts-details-container overflow-y-auto mobile-scroll"
      }
      style={{ height: mobile ? "619px" : "635px" }}
    >
      <div className="w-full mt-6 p-4 bg-lightGray rounded-lg h-[calc(100%-1.5rem)] flex flex-col justify-center">
        <p className="text-center">Preview unavailable for Hubspot forms.</p>
      </div>
      {popup?.show_logo && (
        <div className="absolute bottom-5 right-7">
          <PowerByIconSVG className="w-40" />
        </div>
      )}
    </div>
  );
}
