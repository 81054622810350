import { useState } from "react";
import styled from "styled-components";
import CouponScissors from "./coupon-scissors";

const ButtonContainer = styled.div`
  cursor: ${({ open, formsActive }) =>
    open && !formsActive ? "default" : "pointer"} !important;
  position: relative;
  background: transparent;
  padding: 0;

  border-radius: ${({ cornerStyle }) =>
    cornerStyle === "rounded"
      ? "99999px"
      : cornerStyle === "curved"
      ? "1rem"
      : "0rem"};

  --timing-function: cubic-bezier(0.68, -0.6, 0.32, 1.6);
`;

const ButtonCodeContainer = styled.div`
  border: 2px ${({ open }) => (open ? "solid" : "dashed")}
    ${({ color }) => color};

  border-radius: ${({ cornerStyle }) =>
    cornerStyle === "rounded"
      ? "99999px"
      : cornerStyle === "curved"
      ? "1rem"
      : "0rem"};

  width: 100%;
  text-align: center;
  padding: 1rem;
  font-weight: bold;
  font-size: 1rem;
  color: ${({ color, buttonTextColor, active }) =>
    active ? buttonTextColor : color};
  background-color: ${({ color, active }) => (active ? color : "0")};

  position: relative;
  overflow: hidden;

  transition:
    background-color 300ms,
    color 300ms;
`;

const ButtonCoverContainer = styled.div`
  border-radius: ${({ cornerStyle }) =>
    cornerStyle === "rounded"
      ? "99999px"
      : cornerStyle === "curved"
      ? "1rem"
      : "0rem"};

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  overflow: hidden;
`;

const Cover = styled.div`
  pointer-events: none;

  text-transform: uppercase;
  font-size: 0.925rem;
  font-weight: bold;
  background-color: ${({ color }) => color};
  color: ${({ buttonTextColor }) => buttonTextColor};

  position: absolute;
  top: 0;
  right: 2.5rem;
  left: -4rem;
  bottom: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4rem;

  box-shadow: ${({ open }) =>
    open ? "8px 0 20px #000000ff !important" : "2px 0 6px #00000066"};

  border-radius: ${({ cornerStyle }) =>
    cornerStyle === "rounded"
      ? "99999px"
      : cornerStyle === "curved"
      ? "1rem"
      : "0rem"};

  transform: ${({ open }) =>
    open
      ? "translateX(-110%) scaleX(0.8) !important"
      : "translateX(0) scaleX(1)"};
  transition:
    transform 500ms var(--timing-function),
    box-shadow 500ms var(--timing-function);

  ${ButtonContainer}:hover & {
    transform: translateX(-1rem) scaleX(0.95);
    box-shadow: 8px 0 10px #00000055;
  }
`;

const Code = styled.div`
  transform: ${({ open, canBeSelected, active }) =>
    open
      ? canBeSelected
        ? active
          ? "translateX(0%) !important"
          : "translateX(calc(10px + 0.25rem)) !important"
        : "translateX(0%) !important"
      : "translateX(30%)"};
  transition: transform 500ms var(--timing-function);
  display: flex;
  gap: 0.5rem;
  justify-content: center;

  svg {
    border-radius: 9999px;
    opacity: ${({ active }) => (active ? "1" : "0")};
    transition: opacity 200ms;
    width: 20px;
  }

  ${ButtonContainer}:hover & {
    transform: translateX(35%);
  }
`;

const Scissors = styled.div`
  transform: translateX(${({ open }) => (open ? "-100px" : "0")})
    translateY(-50%);
  transition:
    transform 400ms var(--timing-function),
    opacity 400ms;
  opacity: ${({ open }) => (open ? "0" : "1")};
  z-index: 2;

  position: absolute;
  top: 0;
  right: 1rem;
`;

export default function CouponButton({
  code,
  color,
  buttonTextColor,
  cornerStyle,
}) {
  const [open, setOpen] = useState(false);

  return (
    <ButtonContainer
      open={open}
      color={color}
      cornerStyle={cornerStyle}
      onClick={() => setOpen(true)}
      title={open ? "" : "Reveal Coupon"}
      aria-pressed={open}
    >
      <Scissors open={open}>
        <CouponScissors color={color} />
      </Scissors>
      <ButtonCoverContainer open={open} color={color} cornerStyle={cornerStyle}>
        <Cover
          open={open}
          color={color}
          buttonTextColor={buttonTextColor}
          cornerStyle={cornerStyle}
        >
          <div>Reveal Code</div>
        </Cover>
      </ButtonCoverContainer>
      <ButtonCodeContainer
        open={open}
        color={color}
        buttonTextColor={buttonTextColor}
        cornerStyle={cornerStyle}
      >
        <Code aria-hidden={"true"} open={open}>
          {code}
        </Code>
      </ButtonCodeContainer>
    </ButtonContainer>
  );
}
