import CheckIconSVG from "assets/svg/check-icon-circle";
import ImageUploadIconSVG from "assets/svg/image-upload-icon";
import PowerByIconSVG from "assets/svg/powered-by-icon";
import ButtonPreview from "components/button/button-preview";
import InputPreview from "components/input/input-preview";
import { Context } from "context/context";
import _ from "lodash";
import { useContext } from "react";
import ReactPlayer from "react-player";
import { btnCorner } from "utils/constant";
import ContactDetails from "../component/contacts-details";
import HeaderExample from "../component/header";

const showDummy = ["template", "content", "contactM", "contactD"];

export default function LeadMagnetPopout({ popup }) {
  const { view, selectedTab } = useContext(Context);
  const mobile = view === "mobile";
  const data =
    popup.contact_method_content && JSON.parse(popup.contact_method_content);
  const show = showDummy.includes(selectedTab);

  return (
    <div
      className={
        mobile
          ? "overflow-y-auto mobile-scroll"
          : "contacts-details-container overflow-y-auto mobile-scroll"
      }
      style={{ height: mobile ? "auto" : "635px" }}
    >
      {!_.isEmpty(data) && (
        <div>
          <HeaderExample
            data={data}
            popup={popup}
            show={show}
            className="font-medium"
            placeholder="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore"
          />

          <ContactDetails
            data={data}
            popup={popup}
            show={show}
            mobile={mobile}
          />
          <div className={mobile ? "" : "grid grid-cols-2 gap-x-2"}>
            <InputPreview
              label={
                data.name_input_label ? data.name_input_label : "First Name"
              }
              show={data.show_name}
            />
            <InputPreview
              label={
                data.email_input_label
                  ? data.email_input_label
                  : "Email Address"
              }
              show={data.show_email_address}
            />
            <InputPreview label="Phone" show={data.show_phone} />
            <InputPreview
              label="Enquiry Type"
              show={data.show_enquiry_type}
              select={true}
            />
          </div>
          <div>
            <InputPreview
              label="Message"
              textArea={true}
              show={data.show_message}
            />
          </div>
          <div className="mt-4">
            <ButtonPreview
              buttonName={data.submit_button?.text || (show && "Submit")}
              hidden={
                show
                  ? !data.submit_button?.visible
                  : !data.submit_button?.visible ||
                    _.isEmpty(data.submit_button?.text)
              }
              className="rounded-full py-2 px-6 mr-1 mb-2"
              radius={btnCorner(popup?.button_corner_style)}
              textColor={
                show && _.isEmpty(data.submit_button?.text)
                  ? "#fff"
                  : popup?.button_font_colour || "#fff"
              }
              font={popup?.font?.family}
              background={
                show && _.isEmpty(data.submit_button?.text)
                  ? "#B9BABB"
                  : popup?.button_colour || "#B9BABB"
              }
            />
            <div
              className={`${
                show && !data.image_url && !data.video_link
                  ? "lead-empty my-2"
                  : data.image_url || data.video_link
                  ? "my-2"
                  : ""
              }`}
            >
              {data.video_link && (
                <ReactPlayer
                  url={data.video_link}
                  playing={false}
                  controls
                  width="100%"
                  height="100%"
                  css={{
                    position: `absolute`,
                    top: 0,
                    left: 0,
                  }}
                />
              )}
              {data.image_url && (
                <img
                  src={data.image_url}
                  alt="lead-magnet"
                  className="w-full"
                  //style={{ height: "161px" }}
                />
              )}
              {!data.image_url && !data.video_link && show && (
                <div className="m-auto">
                  <p className="promotion-color grid">
                    <span className="mx-auto">
                      <ImageUploadIconSVG className="h-10 w-10" />
                    </span>
                    Photo or Video
                  </p>
                </div>
              )}
            </div>
            <p
              className={`text-xs mt-2 flex ${
                !data.text?.visible && "hidden"
              } ${!data.text?.value ? "text-tabBorder" : "text-tipsColor"} ${
                data.text?.value || show ? "" : "hidden"
              }`}
            >
              {data.show_ticks && (
                <div
                  className={`mt-0.5 mr-2 ${
                    !data.text?.value && show ? "opacity-50" : ""
                  }`}
                >
                  <CheckIconSVG
                    className="w-3.5 h-3.5"
                    fill={popup.button_colour}
                  />
                </div>
              )}
              {data.text?.value || "More Text for Disclaimer / Message"}
            </p>
          </div>
        </div>
      )}
      {popup?.show_logo && (
        <div className="absolute bottom-5 right-7">
          <PowerByIconSVG className="w-40" />
        </div>
      )}
    </div>
  );
}
