import { Context } from "context/context";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { DisplayErrorMessage } from "utils/utils";
import CalandyEventSchedulerDetails from "./contact-method-details/calendy-event-scheduler-details";
import EnquiryDetails from "./contact-method-details/enquiry-form-details";
import HubspotFormDetails from "./contact-method-details/hubspot-form";
import LeadMagnetDetails from "./contact-method-details/lead-magnet-details";
import MultipleAddressesDetails from "./contact-method-details/multiple-addresses-details";
import StandardDetails from "./contact-method-details/standard-details";

const CampaignChooseContactMethodDetails = () => {
  const { popup, setPopup, selectedTab, setSelectedTab } = useContext(Context);
  const { http } = global.services;
  const [state, setState] = useState({
    media_id: null,
    uploading: false,
    loading: false,
    validationErrors: null,
  });

  const handleChangeStep = () => {
    let content = JSON.parse(popup.contact_method_content);

    if (popup.contact_method_type === "calendy-event-scheduler") {
      if (_.isEmpty(content.link)) {
        setState((prev) => ({
          ...prev,
          validationErrors: {
            ...prev.validationErrors,
            link: "Calendly Link is required",
          },
        }));
        toast.error("Calendly Link is required");
      } else {
        setSelectedTab("style");
      }
    } else if (popup.contact_method_type === "hubspot-form") {
      if (_.isEmpty(content.portalId)) {
        setState((prev) => ({
          ...prev,
          validationErrors: {
            ...prev.validationErrors,
            portalId: "Portal ID is required",
          },
        }));
        toast.error("Portal ID is required");
      } else if (_.isEmpty(content.formId)) {
        setState((prev) => ({
          ...prev,
          validationErrors: {
            ...prev.validationErrors,
            formId: "Form ID is required",
          },
        }));
        toast.error("Form ID is required");
      } else {
        setSelectedTab("style");
      }
    } else {
      let errors = {};
      let err = [];

      if (_.isEmpty(content.title)) {
        errors = {
          ...errors,
          title: "Contact title is required",
        };
        err.push("Contact title is required");
      }

      if (popup.contact_method_type === "enquiry-form") {
        if (content.show_enquiry_type) {
          if (_.isEmpty(content.enquiry_type_options)) {
            errors = {
              ...errors,
              show_enquiry_type: {
                value: ["The Enquiry Type needs at least one(1) option."],
              },
            };
            err.push("The Enquiry Type needs at least one(1) option.");
          }
        }
        if (_.isEmpty(content.submit_button.text)) {
          errors = {
            ...errors,
            submit_button: {
              text: ["A Button Name is required."],
            },
          };
          err.push("A Button Name is required.");
        }
      }

      if (
        popup.contact_method_type === "lead-magnet" &&
        (_.isEmpty(content.submit_button.text) ||
          _.isEmpty(content.submit_button.email))
      ) {
        let text = _.isEmpty(content.submit_button.text)
          ? "A Button Name is required."
          : "";
        let email = _.isEmpty(content.submit_button.email)
          ? "The Email Address is required."
          : "";

        errors = {
          ...errors,
          submit_button: {
            text: [text],
            email: [email],
          },
        };

        if (text) {
          err.push(text);
        } else if (email) {
          err.push(email);
        }
      }

      if (!_.isEmpty(errors)) {
        setState((prev) => ({
          ...prev,
          validationErrors: {
            ...prev.validationErrors,
            ...errors,
          },
        }));
        toast.error(<DisplayErrorMessage errors={err} />);
      } else {
        setSelectedTab("style");
      }
    }
  };

  const handleOnSave = async (content) => {
    content.type = popup.contact_method_type;
    if (popup.contact_method_type === null) return;

    setState({ ...state, loading: true });
    const params = {
      contact_method_content: JSON.stringify(content),
    };

    try {
      if (popup?.id) {
        setPopup({
          ...popup,
          contact_method_content: params?.contact_method_content,
        });
        await http.put(`popups/${popup?.id}`, params);
      }
    } catch (error) {
      if (error.status === 422) {
        setState((prev) => ({
          ...prev,
          validationErrors: error.data.data,
        }));
      }
      toast.error(
        error.status === 422
          ? "Something went wrong! Please check all fields."
          : "It appears that something went wrong"
      );
    } finally {
      setState((prev) => ({ ...prev, loading: false, uploading: false }));
    }
  };

  useEffect(() => {
    if (selectedTab === "contactM")
      setState((prev) => ({ ...prev, validationErrors: null }));
  }, [selectedTab]);

  return (
    <div className="w-full pb-7">
      {popup.contact_method_type === "standard-details" && (
        <StandardDetails
          state={state}
          setState={setState}
          handleChangeStep={handleChangeStep}
          handleOnSave={handleOnSave}
        />
      )}
      {popup.contact_method_type === "enquiry-form" && (
        <EnquiryDetails
          state={state}
          setState={setState}
          handleChangeStep={handleChangeStep}
          handleOnSave={handleOnSave}
        />
      )}
      {popup.contact_method_type === "lead-magnet" && (
        <LeadMagnetDetails
          state={state}
          setState={setState}
          handleChangeStep={handleChangeStep}
          handleOnSave={handleOnSave}
        />
      )}
      {popup.contact_method_type === "multiple-addresses" && (
        <MultipleAddressesDetails
          state={state}
          setState={setState}
          handleChangeStep={handleChangeStep}
          handleOnSave={handleOnSave}
        />
      )}
      {popup.contact_method_type === "calendy-event-scheduler" && (
        <CalandyEventSchedulerDetails
          state={state}
          setState={setState}
          handleChangeStep={handleChangeStep}
          handleOnSave={handleOnSave}
        />
      )}
      {popup.contact_method_type === "hubspot-form" && (
        <HubspotFormDetails
          state={state}
          setState={setState}
          handleChangeStep={handleChangeStep}
          handleOnSave={handleOnSave}
        />
      )}
    </div>
  );
};

export default CampaignChooseContactMethodDetails;
