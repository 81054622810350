import PowerByIconSVG from "assets/svg/powered-by-icon";
import { useEffect, useRef, useState } from "react";
import CCIconLogoContainer from "../commons/cc-logo-container";

const hubspotScriptId = "hubspot-forms-embed-script";
const hubspotDivId = "hubspot-form-target";

const HubspotForm = ({ data }) => {
  const [formLoaded, setFormLoaded] = useState(false);
  const [loadedPortalId, setLoadedPortalId] = useState();
  const [loadedFormId, setLoadedFormId] = useState();
  const [loadedRegion, setLoadedRegion] = useState();
  const divRef = useRef(null);

  useEffect(() => {
    if (!data.portalId || !data.formId) {
      return;
    }

    if (
      formLoaded &&
      data.portalId === loadedPortalId &&
      data.formId === loadedFormId &&
      data.region === loadedRegion
    ) {
      return;
    }

    setLoadedPortalId(data.portalId);
    setLoadedFormId(data.formId);
    setLoadedRegion(data.region);

    if (data.newStyle) {
      const embedScriptEl = document.createElement("script");
      embedScriptEl.src = `https://js.hsforms.net/forms/embed/${data.portalId}.js`;

      const embedDivEl = document.createElement("div");
      embedDivEl.className = "hs-form-frame";
      embedDivEl.dataset.portalId = data.portalId;
      embedDivEl.dataset.formId = data.formId;
      embedDivEl.dataset.region = data.region;

      divRef.current.replaceChildren(embedScriptEl, embedDivEl);

      setFormLoaded(true);
    } else {
      const loadForm = () => {
        divRef.current.replaceChildren();
        window.hbspt.forms.create({
          portalId: data.portalId,
          formId: data.formId,
          target: `#${hubspotDivId}`,
        });
        setFormLoaded(true);
      };

      if (window.hbspt) {
        loadForm();
        return;
      }

      let scriptEl = document.getElementById(hubspotScriptId);

      if (!scriptEl) {
        scriptEl = document.createElement("script");
        scriptEl.id = hubspotScriptId;
        scriptEl.type = "text/javascript";
        scriptEl.src = "//js.hsforms.net/forms/embed/v2.js";
        document.head.appendChild(scriptEl);
      }

      scriptEl.addEventListener("load", loadForm);

      return () => {
        scriptEl.removeEventListener("load", loadForm);
      };
    }
  }, [data, loadedPortalId, loadedFormId, loadedRegion, formLoaded]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ width: "100%", height: "100%" }}>
        <div id={hubspotDivId} ref={divRef} />
      </div>
      {data?.show_logo && (
        <CCIconLogoContainer>
          <PowerByIconSVG />
        </CCIconLogoContainer>
      )}
    </div>
  );
};

export default HubspotForm;
